export default function clickOutside(node, { enabled: initialEnabled, cb }) {
  const handleOutsideClick = ({ target }) => {
    if (!node.contains(target)) {
      cb();
    }
  };

  async function update({ enabled }) {
    if (enabled) {
      setTimeout(() => {
        window.addEventListener("click", handleOutsideClick);
      }, 500);
    } else {
      window.removeEventListener("click", handleOutsideClick);
    }
  }

  update({ enabled: initialEnabled });
  return {
    update,
    destroy() {
      window.removeEventListener("click", handleOutsideClick);
    },
  };
}
