<style>
  .animation-container {
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .lightning-container {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    transform: translateY(-50%);
  }

  .lightning {
    position: absolute;
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 0px;
    transform-origin: 6px 6px;

    animation-name: woosh;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    animation-direction: alternate;
  }

  .white {
    background-color: #fa4545;
  }

  .red {
    background-color: #fa4545;
    animation-delay: 0.2s;
  }

  @keyframes woosh {
    0% {
      width: 12px;
      transform: translate(30vw, 0px) rotate(0deg);
    }
    15% {
      width: 50px;
    }
    50% {
      transform: translate(50vw, 0px) rotate(0deg);
      width: 110px;
    }

    85% {
      width: 50px;
    }
    100% {
      width: 12px;
      transform: translate(70vw, 0px) rotate(0deg);
    }
  }

  .disclaimer {
    width: 30%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    .lightning {
      height: 6px;
    }
    @keyframes woosh {
      0% {
        width: 12px;
        transform: translate(20vw, 0px) rotate(0deg);
      }
      15% {
        width: 50px;
      }
      50% {
        transform: translate(50vw, 0px) rotate(0deg);
        width: 110px;
      }

      85% {
        width: 50px;
      }
      100% {
        width: 12px;
        transform: translate(80vw, 0px) rotate(0deg);
      }
    }
    .disclaimer {
      width: 80%;
    }
  }
</style>

<script>
  import { onMount, onDestroy } from "svelte";
  import queryString from "query-string";
  import { appendProtocol } from "../utils/urlFilterMethods";

  export let competitorUrl;

  const { url } = queryString.parse(location.search);
  let phoneContainerMarginLeft = findPhoneContainerLeftMargin();
  let screenCount = 0;

  let animationInterval;
  onMount(() => {
    screenCount = 1;
    animationInterval = setInterval(() => {
      if (screenCount <= 3) {
        screenCount = screenCount + 1;
      } else {
        screenCount = 1;
      }
    }, 5000);
  });

  onDestroy(() => clearInterval(animationInterval));

  const config = {
    0: {
      message: "Auditing your website",
    },
    1: {
      message: "Auditing your website",
    },
    2: {
      message: "Testing page speed",
    },
    3: {
      message: "Counting $$$",
    },
  };

  const loaderConfig = (screen, prop) => {
    return config[Math.min(screen, 3)][prop];
  };

  function findPhoneContainerLeftMargin() {
    const width = document.body.scrollWidth;
    let widthRatio = width >= 1680 ? 10 / 12 : 11 / 12;
    const finalWidth = Math.min(1200, widthRatio * width);
    return (width - finalWidth) / 2;
  }
</script>

<div
  class="relative flex items-center justify-center flex-1 w-full h-screen bg-brand-gray h-full overflow-hidden"
  style="min-height:100vh"
>
  <!-- user website -->
  <div
    style="top:50%;left:{phoneContainerMarginLeft}px;transform: translateY(-40%);max-width:260px;width:30%"
    id="phone-container"
    class="z-10 fixed float-left md:-mb-20"
  >
    <div
      class="hidden p-1 mt-4 overflow-hidden bg-white rounded-phone h-phone md:block"
      style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
    >
      <img
        class="object-contain object-top w-full h-full rounded-phone"
        style="opacity:0.2"
        src="https://image.thum.io/get/auth/15030-489357e7d4ffa46eb30fa08f26ed67c4/get/width/502/viewportWidth/502/crop/944/{appendProtocol(url)}"
        alt="Screenshot of {url}"
      />
    </div>
    <p class="hidden mt-4 text-center text-gray-500 text-body-large md:block">
      {url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}
    </p>
</div>
  <!-- competitor website -->
  {#if competitorUrl}
  <div
    style="top:50%;right:{phoneContainerMarginLeft}px;transform:translateY(-40%);max-width: 260px;width:30%;"
    id="phone-container"
    class="z-10 fixed md:-mb-20 sm:hidden md:block sm:z-10"
  >
    <div
      class="hidden p-1 mt-4 overflow-hidden bg-white rounded-phone h-phone md:block"
      style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
    >
      <img
        class="object-contain object-top w-full h-full rounded-phone"
        style="opacity:0.2"
        src="https://image.thum.io/get/auth/15030-489357e7d4ffa46eb30fa08f26ed67c4/get/width/502/viewportWidth/502/crop/944/{appendProtocol(competitorUrl)}"
        alt="Screenshot of {competitorUrl}"
      />
    </div>
    <p class="hidden mt-4 text-center text-gray-500 text-body-large md:block">

      {competitorUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}
  
    </p>
  </div>
{/if}

  <div class="flex justify-center flex-col w-screen">
    <div class="animation-container w-full">
      <div class="lightning-container">
        <div class="lightning white"></div>
        <div class="lightning red"></div>
      </div>
    </div>
    <br />
    <h3 class="text-white text-body-jumbo text-center animate-pulse">
      {loaderConfig(screenCount, 'message')}
    </h3>
    <p class="text-gray-700 text-center px-4 py-2 disclaimer">
      Fluctuations in your performance score are normal and to be expected.
    </p>
  </div>
</div>
