<style>
  .initial-page {
    top: 10vh;
    left: 10px;
  }
  .full-page {
    position: fixed;
    display: block;
    top: 0px;
    height: 100vh;
    width: 100vw;
    left: 0px;
    z-index: 10;
  }
  .full-page .flex-form {
    justify-content: space-between;
  }
  /* lottie-player {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: -999;
  } */
  .btn-box-shadow {
    box-shadow: none;
  }

  .btn-box-shadow:hover {
    box-shadow: 0px 4px 16px 0px rgba(1, 0, 0, 0.25);
  }
  .title-heading {
    font-size: calc(32px + (64 - 32) * ((100vw - 320px) / (1600 - 320)));
    font-weight: 800;
    line-height: 130%;
    letter-spacing: -0.02em;
  }

  .text-body-large {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1600 - 320)));
    line-height: 170%;
    letter-spacing: 0.02em;
  }
</style>

<script>
  import { fade } from "svelte/transition";
  import { linear } from "svelte/easing";
  import { LottiePlayer } from "@lottiefiles/svelte-lottie-player";
  import { navigate } from "svelte-routing";
  import { industryAverages } from "../utils/scoringCalculator";
  import clickOutside from "../utils/clickOutside";
  import Header from "../components/Header.svelte";
  import deviceInfo from "../utils/deviceInfo";
  let { os } = deviceInfo(window.navigator.userAgent);

  let formFullScreen = false;
  let isFormValid = false;
  function handleFormClick() {
    if (window.innerWidth > 455) return;
    formFullScreen = true;
    // Hack to prevent input going off viewport issue
    if (os && os.name && os.name.toLowerCase() === "ios") {
      setTimeout(e => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.querySelector("form").style.justifyContent = "flex-start";
      }, 100);
    }
  }

  function handleKeydown(e) {
    if (e.keyCode === 13 && isFormValid) {
      navigate(`report?url=${inputUrl}&category=${selectedIndustryIndex}`);
    }
  }

  function handleSubmit(e) {
    e.preventDefault(); 
    let modifiedInputUrl = inputUrl;
    // if (!/^https?:\/\//i.test(inputUrl)) {
    //   modifiedInputUrl = "http://" + inputUrl;
    // }
    navigate(
      `report?url=${modifiedInputUrl}&category=${selectedIndustryIndex}`,
    );
  }
  function checkFormValidity(url, industry) {
    let result = url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );

    if (result && industry) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
  }

  $: checkFormValidity(inputUrl, selectedIndustry);
  let inputUrl = "";

  let selectedIndustry = "";
  let selectedIndustryIndex = -1;
  let isSelectOpen = false;
</script>

<svelte:head>
  <title>Peak</title>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover, width=device-width"
  />
  <meta name="theme-color" content="#19222F" />
  <meta name="msapplication-navbutton-color" content="#19222F" />
  <meta name="apple-mobile-web-app-status-bar-style" content="#19222F" />
  <style>
    body {
      background-color: #19222f;
    }

    .lottie-player {
      position: absolute;
      width: 100vw;
      height: 100vh;
      max-width: 1088px;
      min-width: 956px;
      top: 0;
      left: calc(50% - 1088px / 2 + 247px);
      opacity: 0.6;
    }

    @media only screen and (max-width: 600px) {
      .lottie-player {
        width: 956px;
        height: 628px;
        left: calc(50% - 956px / 2 + 108.5px);
      }
    }
  </style>
  <!-- Google Tag Manager -->
  <script>
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TB2K5G6");
  </script>
  <!-- End Google Tag Manager -->
</svelte:head>

<svelte:window on:keydown="{handleKeydown}" />

<div class="bg-brand-gray">
  <LottiePlayer
    src="https://global-uploads.webflow.com/5e6b77d73dfad80c8dcce8de/5e6eb69ce64d7d2ba7d9f118_proximity-space-ship-animation.json"
    autoplay="{true}"
    renderer="svg"
    loop
    style="position:fixed;width:100vw;height:100vh;opacity:0.1;pointer-events:none;"
    background="transparent"
  />
  <div
    class="flex flex-col justify-between w-11/12 max-w-screen-lg min-h-screen md:pt-6 pt-4 pb-4 mx-auto md:justify-start md:w-10/12"
  >
    <Header />

    <main class="mt-10 text-white md:my-auto md:w-2/3">
      <h1 class="tracking-wide title-heading">1 second can cost you.</h1>
      <h1 class="mt-0 tracking-wide title-heading">A lot. </h1>
      <p class="text-body-large md:mt-8 mt-4">
        Speed equals revenue. 53% of consumers will drop off if your load time
        goes over 3 seconds, and every 100ms delay can cause a 7% drop in
        conversions. How does performance affect your business?
      </p>
      <div
        class="initial-page {formFullScreen ? 'mt-0' : 'mt-9'}
        bg-white p-4 md:bg-transparent shadow-sm md:p-0 transition-all duration-300 ease-out {formFullScreen ? 'full-page' : ''}"
      >
        {#if formFullScreen}
          <div class="absolute -mr-2" style="top:0;right:10px">
            <button
              on:click="{() => {
                formFullScreen = false;
                document.querySelector('form').style.justifyContent = '';
              }}"
              type="button"
              class="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-brand-gray hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-100"
              aria-label="Close menu"
            >
              <svg
                class="w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        {/if}
        <form
          action=""
          class="flex flex-col h-full space-y-3 text-brand-gray flex-form"
          on:click="{handleFormClick}"
          on:submit|preventDefault
        >
          <div
            class="flex flex-col space-y-3 md:flex-row md:space-y-0 flex-form min-h-10"
          >
            <div
              class="flex items-center md:flex-1 h-full"
              style="box-shadow: 0px 3px 10px 0px #000000 20%;
            "
            >
              <input
                type="text"
                name="url"
                id="url"
                bind:value="{inputUrl}"
                autocomplete="off"
                placeholder="Your URL"
                class="flex-1 py-5 pl-5 placeholder-gray-500 border-b-2 border-gray-300 outline-none md:px-5 md:border-gray-100 md:border-r-2 text-ui h-10"
              />
            </div>

            <div
              class="flex items-center tracking-wide md:w-1/3 text-ui text-brand-gray min-h-10 h-10"
              use:clickOutside="{{ enabled: isSelectOpen, cb: () => (isSelectOpen = false) }}"
            >
              <div class="relative w-full h-full">
                <span class="inline-block w-full h-full">
                  <button
                    type="button"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    tabindex="0"
                    on:click="{() => (isSelectOpen = !isSelectOpen)}"
                    on:focus="{() => (isSelectOpen = true)}"
                    aria-labelledby="listbox-label"
                    class="focus:bg-red relative w-full h-full py-2 pr-10 pl-5 text-left transition duration-150 ease-in-out bg-white border-b-2 border-gray-300 cursor-default md:border-gray-100 focus:outline-none hover:bg-gray-100 cursor-pointer"
                  >
                    <span class="block truncate outline-none" tabindex="0">
                      {selectedIndustry || 'Industry'}
                    </span>
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none"
                    >
                      <svg
                        class="w-5 h-5 text-gray-400 text-brand-red"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.40625 7.82812L12 12.4219L16.5938 7.82812L18 9.23438L12 15.2344L6 9.23438L7.40625 7.82812Z"
                          fill="#FA4545"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </span>

                <!-- Select popover, show/hide based on select state. -->
                {#if isSelectOpen} 
                  <div
                  transition:fade={{duration:150,easing:linear}} 
                  class="absolute w-full mt-0 tracking-wide bg-white shadow-lg text-ui text-brand-gray" >
                  <ul tabindex="0" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="py-1 overflow-auto text-base leading-6 max-h-fifthscreen focus:outline-none ">
                    {#each industryAverages as {type:industry},index }
                    <li id="listbox-option-0" role="option" class="relative px-3 py-2 text-gray-700 cursor-pointer select-none hover:bg-gray-100 focus:bg-gray-100" on:click={()=>{
                      selectedIndustry=industry;
                      selectedIndustryIndex= index;
                      isSelectOpen =false;
                    }}>
                      <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                      <span tabindex="0" class="block outline-black {selectedIndustry===industry?'font-extrabold text-brand-gray':""}">
                        {industry}
                      </span>
                     
                    </li>
                    {/each}
                    
                    
                  </ul>
                </div>
                {/if}
              </div>
            </div>
          </div>
          <div class="text-center md:inline-block md:self-start">
            <button
              on:click="{handleSubmit}"
              disabled="{!isFormValid}"
              type="button"
              tabindex="0"
              class="w-full px-8 py-3 font-bold h-10 focus:outline-none {!isFormValid ? 'bg-gray-100  md:bg-gray-700 md:opacity-25 text-gray-500' : 'bg-brand-red text-white btn-box-shadow'}"
            >Test Performance</button>
          </div>
        </form>
      </div>
    </main>
  </div>
</div>
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TB2K5G6"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
