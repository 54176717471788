export default function isElementOutOfViewport(
  phoneContainer,
  reviewContainer,
  scrollY,
) {
  const phoneEl = document.querySelector(phoneContainer);
  const reviewContainerEl = document.querySelector(reviewContainer);
  if (!phoneEl || !reviewContainerEl) return;

  const scroll = scrollY;
  const main = document.querySelector("html");
  if (+scroll >= +reviewContainerEl.offsetTop - 250) {
    phoneEl.classList.add("sticky");
    main.style.overflowX = "visible";
  } else {
    phoneEl.classList.remove("sticky");
    main.style.overflowX = "hidden";
  }
}
