export const performanceData = {
  "first-contentful-paint": {
    good: [0, 2],
    average: [2.1, 4],
    poor: [4.1, -1],
  },
  "speed-index": {
    good: [0, 4.3],
    average: [4.3, 5.8],
    poor: [5.9, -1],
  },
  "largest-contentful-paint": {
    good: [0, 2.5],
    average: [2.6, 4.0],
    poor: [4.0, -1],
  },
  interactive: {
    good: [0, 3.8],
    average: [3.9, 7.3],
    poor: [7.4, -1],
  },
  "total-blocking-time": {
    good: [0, 0.3],
    average: [0.31, 0.6],
    poor: [0.6, -1],
  },
  "cumulative-layout-shift": {
    good: [0, 0.1],
    average: [0.11, 0.25],
    poor: [0.26, -1],
  },
};
