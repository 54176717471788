const CACHING_DELAY = 60 * 5; // 5 Mins
const LOCAL_STORAGE = {
  URL: "cached-url",
  DATA: "cached-data",
  TIME: "cache-time",
  CATEGORY: "cache-category",
  COMP_URL: "cached-comp-url",
  COMP_DATA: "cached-comp-data",
  COMP_TIME: "cache-comp-time",
  COMP_CATEGORY: "cache-comp-category",
};

const unixTimeNow = () => {
  return Math.round(new Date() / 1000);
};

const hostName = url => {
  return url ? new URL(url).hostname.replace(/www./gi, "") : "";
};

export function getSiteData(url, category) {
  if (
    hostName(localStorage.getItem(LOCAL_STORAGE.URL)) === hostName(url) &&
    localStorage.getItem(LOCAL_STORAGE.CATEGORY) === category
  ) {
    let lastFetch = localStorage.getItem(LOCAL_STORAGE.TIME);
    let diff = unixTimeNow() - lastFetch;
    if (diff < CACHING_DELAY) {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE.DATA));
    } else {
      return false;
    }
  }
  return false;
}

export function getCompSiteData(url, category) {
  if (
    hostName(localStorage.getItem(LOCAL_STORAGE.COMP_URL)) === hostName(url) &&
    localStorage.getItem(LOCAL_STORAGE.COMP_CATEGORY) === category
  ) {
    let lastFetch = localStorage.getItem(LOCAL_STORAGE.COMP_TIME);
    let diff = unixTimeNow() - lastFetch;
    console.log("diff is ", diff);
    if (diff < CACHING_DELAY) {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE.COMP_DATA));
    } else {
      return false;
    }
  }
  return false;
}

export function setSiteData(url, category, data) {
  if (url && data) {
    localStorage.setItem(LOCAL_STORAGE.URL, url);
    localStorage.setItem(LOCAL_STORAGE.CATEGORY, category);
    localStorage.setItem(LOCAL_STORAGE.DATA, JSON.stringify(data));
    localStorage.setItem(LOCAL_STORAGE.TIME, unixTimeNow());
  }
}

export function setCompData(url, category, data) {
  if (url && data) {
    localStorage.setItem(LOCAL_STORAGE.COMP_URL, url);
    localStorage.setItem(LOCAL_STORAGE.COMP_CATEGORY, category);
    localStorage.setItem(LOCAL_STORAGE.COMP_DATA, JSON.stringify(data));
    localStorage.setItem(LOCAL_STORAGE.COMP_TIME, unixTimeNow());
  }
}

export function trimData(data) {
  let report = JSON.parse(data);
  let { categories, audits } = report.lighthouseResult;

  return { categories, audits };
}
