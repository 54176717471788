export const industryAverages = [
  { type: "Automotive", average: 1600, displayValue: "1.6 s" },
  { type: "Consumer Packaged Goods", average: 1800, displayValue: "1.8 s" },
  { type: "Finance", average: 2300, displayValue: "2.3 s" },
  { type: "Healthcare", average: 1900, displayValue: "1.9 s" },
  { type: "Media and Entertainment", average: 1500, displayValue: "1.5 s" },
  { type: "Retail", average: 1800, displayValue: "1.8 s" },
  { type: "Technology", average: 1800, displayValue: "1.8 s" },
  { type: "Travel", average: 1900, displayValue: "1.9 s" },
];

export const curves = {
  v6: {
    mobile: {
      FCP: { weight: 0.15, median: 4000, p10: 2336 },
      SI: { weight: 0.15, median: 5800, p10: 3387 },
      LCP: { weight: 0.25, median: 4000, p10: 2500 },
      TTI: { weight: 0.15, median: 7300, p10: 3785 },
      TBT: { weight: 0.25, median: 600, p10: 287 },
      CLS: { weight: 0.05, median: 0.25, p10: 0.1 },
    },
    desktop: {
      FCP: { weight: 0.15, median: 1600, p10: 934 },
      SI: { weight: 0.15, median: 2300, p10: 1311 },
      LCP: { weight: 0.25, median: 2400, p10: 1200 },
      TTI: { weight: 0.15, median: 4500, p10: 2468 },
      TBT: { weight: 0.25, median: 350, p10: 150 },
      CLS: { weight: 0.05, median: 0.25, p10: 0.1 },
    },
  },
};

function internalErfInv_(x) {
  var sign = x < 0 ? -1 : 1;
  var a = 0.147;

  var log1x = Math.log(1 - x * x);
  var p1 = 2 / (Math.PI * a) + log1x / 2;
  var sqrtP1Log = Math.sqrt(p1 * p1 - log1x / a);
  return sign * Math.sqrt(sqrtP1Log - p1);
}

function internalErf_(x) {
  // erf(-x) = -erf(x);
  var sign = x < 0 ? -1 : 1;
  x = Math.abs(x);

  var a1 = 0.254829592;
  var a2 = -0.284496736;
  var a3 = 1.421413741;
  var a4 = -1.453152027;
  var a5 = 1.061405429;
  var p = 0.3275911;
  var t = 1 / (1 + p * x);
  var y = t * (a1 + t * (a2 + t * (a3 + t * (a4 + t * a5))));
  return sign * (1 - y * Math.exp(-x * x));
}

function derivePodrFromP10(median, p10) {
  var u = Math.log(median);
  var shape = Math.abs(Math.log(p10) - u) / (Math.SQRT2 * 0.9061938024368232);
  var inner1 = -3 * shape - Math.sqrt(4 + shape * shape);
  var podr = Math.exp(u + (shape / 2) * inner1);
  return podr;
}

export function VALUE_AT_QUANTILE(ref, quantile) {
  var median = ref.median;
  var podr = ref.podr;
  var p10 = ref.p10;

  if (!podr) {
    podr = derivePodrFromP10(median, p10);
  }

  var location = Math.log(median);
  var logRatio = Math.log(podr / median);
  var shape =
    Math.sqrt(
      1 - 3 * logRatio - Math.sqrt((logRatio - 3) * (logRatio - 3) - 8),
    ) / 2;

  return Math.exp(
    location + shape * Math.SQRT2 * internalErfInv_(1 - 2 * quantile),
  );
}
export const QUANTILE_AT_VALUE = function (ref, value) {
  var median = ref.median;
  var podr = ref.podr;
  var p10 = ref.p10;
  if (!podr) {
    podr = derivePodrFromP10(median, p10);
  }
  var location = Math.log(median);
  var logRatio = Math.log(podr / median);
  var shape =
    Math.sqrt(
      1 - 3 * logRatio - Math.sqrt((logRatio - 3) * (logRatio - 3) - 8),
    ) / 2;
  var standardizedX = (Math.log(value) - location) / (Math.SQRT2 * shape);
  return (1 - internalErf_(standardizedX)) / 2;
};
