<style>
  button:focus {
    outline: none;
  }
</style>

<script>
  import { fade } from "svelte/transition";
  import { linear } from "svelte/easing";
  import { industryAverages } from "../utils/scoringCalculator";
  import clickOutside from "../utils/clickOutside";

  export let selectedIndustryIndex = -1;
  export let isSelectOpen = false;
  let selectedIndustry = "";
  $: if (selectedIndustryIndex > -1) {
    selectedIndustry = industryAverages[selectedIndustryIndex].type;
  }
</script>

<div
  class="flex items-center tracking-wide text-body-small text-brand-gray"
  use:clickOutside="{{ enabled: isSelectOpen, cb: () => (isSelectOpen = false) }}"
>
  <div class="relative w-full h-full">
    <span class="inline-block w-full h-full">
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        on:click="{() => (isSelectOpen = !isSelectOpen)}"
        aria-labelledby="listbox-label"
        class="relative w-full h-full py-2 pr-10 text-left transition duration-150 ease-in-out bg-white border-b-2 border-gray-300 cursor-default "
      >
        <span class="block truncate"> {selectedIndustry || 'Industry'} </span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <svg 
            class="w-5 h-5 text-gray-400 text-brand-red" 
            viewBox="0 0 24 24" 
            fill="none"
          >
            <path 
              d="M7.40625 7.82812L12 12.4219L16.5938 7.82812L18 9.23438L12 15.2344L6 9.23438L7.40625 7.82812Z"
              fill="#FA4545"
            ></path>
          </svg>
        </span>
      </button>
    </span>

    <!-- Select popover, show/hide based on select state. -->
    {#if isSelectOpen} 
    <div
    style="z-index:1000"
    transition:fade={{duration:150,easing:linear}} 
    class="absolute w-full mt-1 tracking-wide bg-white shadow-lg text-body-small text-brand-gray" >
    <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="py-1 overflow-auto text-base leading-6 max-h-fifthscreen focus:outline-none ">
      {#each industryAverages as {type:industry},index }
      <li id="listbox-option-0" role="option" class="relative px-3 py-2 text-gray-700 cursor-default select-none hover:bg-gray-100 focus:bg-gray-100" on:click={()=>{
        selectedIndustry=industry;
        selectedIndustryIndex= index;
        isSelectOpen =false;
      }}>
        <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
        <span class="block {selectedIndustry===industry?'font-extrabold text-brand-gray':""}">
          {industry}
        </span>
       
      </li>
      {/each}
      
      
    </ul>
  </div>
  {/if}
  </div>
</div>
