export const protocolLessUrl = url => {
  let filteredUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  if (filteredUrl.substring(filteredUrl.length - 1) == "/") {
    filteredUrl = filteredUrl.substring(0, filteredUrl.length - 1);
  }

  return filteredUrl;
};
export const protocolLessDomain = link => {
  return link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
};

export const appendProtocol = url => {
  return (!/^https?:\/\//i.test(url)) ? "http://" + url : url;
}