<script>
  import { fade } from "svelte/transition";
  import { cubicIn, cubicOut } from "svelte/easing";
  export let enabled;
</script>

{#if enabled}
  <div class="fixed inset-0 z-30">
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0 "
    >
      <div
        class="inset-0 transition-opacity "
        in:fade="{{ duration: 500, easing: cubicOut }}"
        out:fade="{{ duration: 500, easing: cubicIn }}"
      >
        <div class="absolute inset-0 bg-gray-900 opacity-90"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

      <!--
        Modal panel, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->

      <slot></slot>
    </div>
  </div>
{/if}
