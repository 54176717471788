<script>
  import { getEventsAction } from "../utils/inputEvents";
  import { current_component } from "svelte/internal";
  const events = getEventsAction(current_component);
  export let url;
</script>

<input
  style="background-color:rgba(117, 143, 166, 0.2)"
  value="{url}"
  use:events
  class="block w-full pt-2 pb-2 pl-2 pr-10 mx-auto truncate outline-none text-body-regular form-input sm:leading-5 placeholder-gray-390"
/>
