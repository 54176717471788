<style>
  .sticky {
    position: sticky;
  }
  .pointer-events-initial {
    pointer-events: initial;
  }
  button:focus {
    outline: none;
  }
  .citation {
    top: -8px;
    right: 8px;
    line-height: 160%;
    font-size: 12px;
  }
  .sending-mail {
    animation: smooth-scale 5s infinite;
  }
  @keyframes smooth-scale {
    from {
      width: 1%;
    }
    to {
      width: 100%;
    }
  }

  .down-arrow {
    opacity: 1;
  }

  .divider {
    right: 30px;
  }

  .min-w-290 {
    min-width: 290px;
  }
</style>

<script>
  import axios from "axios";
  import { fly } from "svelte/transition";
  import { watchResize } from "svelte-watch-resize";
  import scrollIntoView from "scroll-into-view-if-needed";
  import { navigate } from "svelte-routing";
  import { query } from "@intrnl/svelte-query";
  import queryString from "query-string";
  import {
    QUANTILE_AT_VALUE,
    curves,
    industryAverages,
  } from "../utils/scoringCalculator";
  import clickOutside from "../utils/clickOutside";
  import { performanceData } from "../utils/performanceData";
  import {
    protocolLessDomain,
    protocolLessUrl,
    appendProtocol,
  } from "../utils/urlFilterMethods";
  import currentElementPosition from "../utils/currentElementPosition";
  import isElementOutOfViewport from "../utils/viewportObserver";
  import isEmailValid from "../utils/isEmailValid";
  import { getSiteData, setSiteData, trimData } from "../utils/readStorage";
  import Header from "../components/Header.svelte";
  import Modal from "../components/Modal.svelte";
  import RevenueCalculator from "../components/RevenueCalculator.svelte";
  import Input from "../components/Input.svelte";
  import IndustrySelect from "../components/IndustrySelect.svelte";
  import PageSpeedChart from "../components/PageSpeedChart.svelte";
  import Loader from "../components/Loader.svelte";
  import Footer from "../components/Footer.svelte";
  import Tooltip from "../components/Tooltip.svelte";

  export let location;
  let isCompareModalOpen = false;
  let isTestOnMobileModalOpen = false;
  let { url, category, usecache = 1 } = queryString.parse(location.search);
  let displayUrl = url;
  let initialFormUrl = url;
  const performanceKeys = [
    "first-contentful-paint",
    "speed-index",
    "largest-contentful-paint",
    "interactive",
    "total-blocking-time",
    "cumulative-layout-shift",
  ];
  const PHONE_CONTAINER_MAX_WIDTH = 260;
  const PHONE_CONTAINER_MARGIN_RIGHT = 75;
  let performanceKeyScore = [];
  let performanceOpportunities = [];
  let phoneContainerMarginLeft = findPhoneContainerLeftMargin();
  let performanceScore,
    performanceKeyword,
    performanceReviewUnderlineClass,
    performanceClass,
    pageSpeedClass,
    pageSpeedIndexPercentage,
    pageSpeedIndustryAveragePercentage,
    performanceImageFile,
    performanceMidScore = false;
  async function fetchRepoData(key, urlValue, usecache = 1) {
    let urlTobeSent = urlValue;
    let cached;
    let resp;

    if (!/^https?:\/\//i.test(url)) {
      urlTobeSent = "http://" + url;
    }
    if (!!+usecache) {
      cached = getSiteData(urlTobeSent, category);
      if (cached) return cached;
    }

    try {
      resp = await axios.get(
        "https://content-pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed",
        {
          params: {
            url: urlTobeSent,
            strategry: "MOBILE",
            category: "PERFORMANCE",
            key: "PSI_API_KEY",
          },
        },
      );
    } catch (e) {
      // ToDo: How to handle Error Events?
      alert(e.message);
      window.location = "/";
      return;
    }
    if (!resp.status === 200) throw new Error(`HTTP error: ${resp.status}`);
    setSiteData(urlTobeSent, category, resp.data);
    return resp.data;
  }
  let selectedCompareIndustryIndex = category;
  let isCompareSelectOpen = false;
  let selectedTestIndustryIndex = category;
  let isTestSelectOpen = false;
  let showMetrics = false;
  let q = query(["repoData", url, usecache], fetchRepoData, {
    refetchOnWindowFocus: false,
  });
  let isFormValid = false;
  function recalculateDataPoints(queryData) {
    performanceOpportunities = [];
    performanceKeyScore = [];
    if (queryData && queryData.lighthouseResult) {
      const categories = data.lighthouseResult.categories;
      displayUrl = data.lighthouseResult.finalUrl;
      initialFormUrl = data.lighthouseResult.finalUrl.slice(0, -1);
      history.replaceState(
        {},
        null,
        `report?url=${initialFormUrl}&category=${category}`,
      );
      const audits = data.lighthouseResult.audits;
      performanceKeys.forEach(key => {
        const { description, displayValue, title, numericValue, id } = audits[
          key
        ];
        let formattedDisplayValue = displayValue;
        if (key === "total-blocking-time") {
          formattedDisplayValue = Math.round((numericValue / 1000) * 10) / 10;
          formattedDisplayValue = formattedDisplayValue.toString() + " s";
        }
        performanceKeyScore.push({
          description: description.split(".")[0] + ".",
          displayValue: formattedDisplayValue,
          title,
          numericValue,
          key: id,
        });
      });

      const pageSpeedIndexAuditScore = performanceKeyScore[1].numericValue;

      //rounding off 0.34343 to two decimal places for percentage
      pageSpeedIndexPercentage = Math.round(
        (QUANTILE_AT_VALUE(curves.v6.mobile.SI, pageSpeedIndexAuditScore) +
          Number.EPSILON) *
          100,
      );

      pageSpeedIndustryAveragePercentage = Math.round(
        (QUANTILE_AT_VALUE(
          curves.v6.mobile.SI,
          industryAverages[category].average,
        ) +
          Number.EPSILON) *
          100,
      );

      let pageSpeedIndustryAverageClass = "";
      if (pageSpeedIndustryAveragePercentage < 40) {
        pageSpeedIndustryAverageClass = "poor-gradient";
      } else if (pageSpeedIndustryAveragePercentage < 85) {
        pageSpeedIndustryAverageClass = "average-gradient";
      } else {
        pageSpeedIndustryAverageClass = "good-gradient";
      }
      for (let audit in audits) {
        if (
          audits[audit].details &&
          audits[audit].details.type === "opportunity" &&
          audits[audit].score < 1
        ) {
          performanceOpportunities.push(audits[audit]);
        }
      }
      performanceScore = (categories.performance.score * 100).toFixed(0);
      if (performanceScore < 40) {
        performanceKeyword = "is poor";
        performanceReviewUnderlineClass = "bad";
        performanceClass = "poor-gradient";
        performanceImageFile = "badge-bad";
      } else if (performanceScore < 85) {
        performanceKeyword = "needs improvement";
        performanceMidScore = true;
        performanceReviewUnderlineClass = "needs improvement";
        performanceClass = "average-gradient";
        performanceImageFile = "badge-ok";
      } else {
        performanceKeyword = "is good";
        performanceReviewUnderlineClass = "good";
        performanceClass = "good-gradient";
        performanceImageFile = "badge-good";
      }
      if (pageSpeedIndexPercentage < 40) {
        pageSpeedClass = "poor-gradient";
      } else if (pageSpeedIndexPercentage < 85) {
        pageSpeedClass = "average-gradient";
      } else {
        pageSpeedClass = "good-gradient";
      }
    }
  }

  function isValidURL(url) {
    return url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
  }

  function handleKeyDown(e, modalType, isSubmitEnabled) {
    if (e.keyCode === 13 && isSubmitEnabled) {
      if (modalType === "testmobile") {
        onReportSubmit();
      } else if (modalType === "compare") {
        onCompareSubmit();
      } else if (modalType === "report") {
        sendReportToEmail();
      }
    }
  }

  function checkFormValidity(url, industry) {
    let result = isValidURL(url);

    if (result && (industry || industry == 0)) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
  }

  $: checkFormValidity(initialFormUrl, selectedTestIndustryIndex);
  $: ({ status, error, data, isFetching } = $q);
  $: {
    if (
      isTestOnMobileModalOpen ||
      isDownloadModalVisible ||
      isCompareModalOpen
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
    document.body.style.overflowX = "hidden";
  }
  $: recalculateDataPoints(data);
  let scrollY;
  $: {
    currentElementPosition(
      document.getElementsByClassName("scroll-detector")[0],
      document.getElementsByClassName("inverse-color-input")[0],
      scrollY,
    );
  }
  $: isFirstFold = window.scrollY === 0 ? true : false;
  $: {
    isElementOutOfViewport("#phone-container", "#where-do-you-stand", scrollY);
  }

  let reportEmail = "";
  let mailSent = false;
  let mailSending = false;

  let isDownloadModalVisible = false;
  const openDownloadReportModal = () => (isDownloadModalVisible = true);

  // arrow opacity for icon on email input on download modal
  let isArrowOpaque = false;
  $: isEmailValid(reportEmail)
    ? (isArrowOpaque = false)
    : (isArrowOpaque = true);

  const sendReportToEmail = async () => {
    if (reportEmail && isEmailValid(reportEmail)) {
      // isDownloadModalVisible = true;
      isArrowOpaque = true;
      mailSending = true;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
      };
      let endPoint = "ENDPOINT";
      let resp = await axios.post(
        endPoint + "report/mail",
        {
          email: reportEmail,
          url,
          category,
          data: trimData(localStorage.getItem("cached-data")),
        },
        headers,
      );
      if (resp.status === 200) {
        mailSent = true;
        mailSending = false;
      }
    }
  };
  let competitorUrl = "";
  let isCompareButtonEnabled = false;

  function checkCompareFormValidity(url, industry, competitorUrl) {
    let exp = new RegExp(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    const areBothLinksDifferent =
      protocolLessUrl(url) !== protocolLessUrl(competitorUrl);
    isCompareButtonEnabled =
      url.match(exp) &&
      (industry || industry == 0) &&
      competitorUrl.match(exp) &&
      areBothLinksDifferent;
  }

  function findPhoneContainerLeftMargin() {
    const width = document.body.clientWidth;
    let widthRatio = width >= 1680 ? 10 / 12 : 11 / 12;
    const finalWidth = Math.min(1200, widthRatio * width);
    return (width - finalWidth) / 2;
  }

  $: checkCompareFormValidity(
    initialFormUrl,
    selectedCompareIndustryIndex,
    competitorUrl,
  );

  const onReportSubmit = () => {
    window.location.href = `report?url=${initialFormUrl}&category=${selectedTestIndustryIndex}`;
  };
  const onCompareSubmit = () => {
    if (isCompareButtonEnabled) {
      let modifiedInputUrl = initialFormUrl;
      let modifiedCompetitorUrl = competitorUrl;
      document.body.style.overflow = "initial";
      window.location.href = `compare?url=${modifiedInputUrl}&category=${selectedCompareIndustryIndex}&competitorUrl=${modifiedCompetitorUrl}`;
    }
  };

  const highlightURL = selector => {
    let mobileurl = document.querySelector(selector);
    if (mobileurl) {
      mobileurl.focus();
      mobileurl.setSelectionRange(0, mobileurl.value.length);
    }
  };

  const closeReportModal = () => {
    isDownloadModalVisible = false;
    isArrowOpaque = true;
    mailSent = false;
    if (!mailSending) {
      reportEmail = "";
    }
  };

  const shortURL = url => {
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
  };

  const formatNumber = (value, ignoreString = true, decimal = 2) => {
    return isNaN(value) && ignoreString
      ? value
      : parseFloat(value).toFixed(decimal);
  };

  const getClassName = (val1, key) => {
    let value = formatNumber(val1, false, 2);
    let performanceParam = performanceData[key];
    for (var prop in performanceParam) {
      if (
        value >= performanceParam[prop][0] &&
        value <= performanceParam[prop][1]
      ) {
        return prop;
      }
    }
    return "poor";
  };
</script>

<svelte:head>
  <title>Report</title>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover, width=device-width"
  />
  <meta name="theme-color" content="#19222F" />
  <meta name="msapplication-navbutton-color" content="#19222F" />
  <meta name="apple-mobile-web-app-status-bar-style" content="#19222F" />
  <!-- Google Tag Manager -->
  <script>
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TB2K5G6");
  </script>
  <!-- End Google Tag Manager -->
</svelte:head>

<svelte:window bind:scrollY />

{#if isFetching}
  <Loader />
{:else}
  <div
    class="fixed bottom-0 py-4 z-10 animate-bounce opacity-0"
    style="left:calc(50% - 11px);"
    class:down-arrow="{isFirstFold}"
  >
    <img src="/assets/images/down.svg" alt="down arrow" />
  </div>
  <header
    class="sticky z-20 w-full text-white bg-gray-1000 md:hidden"
    style="top:-30px"
    id="print-to-pdf"
  >
    <div class="w-11/12 max-w-screen-lg pt-2 pb-4 mx-auto sm:pb-6 xl:w-10/12 ">
      <div class="flex items-center ml-2 space-x-4">
        <i class="material-icons">arrow_back</i>
        <span class="tracking-wide text-gray-100 font-bold">Performance Report</span>
      </div>
      <div class="flex items-center space-x-3">
        <div class="relative flex-1 mt-3 rounded-md shadow-sm ">
          <input
            id="mobile-url"
            style="background-color:rgba(255,255,255,0.1)"
            value="{displayUrl}"
            class="block w-full pt-2 pb-2 pl-2 pr-10 text-gray-100 outline-none form-input sm:text-sm sm:leading-5 placeholder-gray-390"
          />
          <div
            onclick="{highlightURL('#mobile-url')}"
            class="absolute inset-y-0 right-0 flex pr-3 mt-3 pointer-events-none"
          >
            <i class="material-icons text-base">mode_edit</i>
          </div>
        </div>
        <div
          class="relative"
          on:click="{() => {
            let url = document.getElementById('mobile-url').value;
            if (isValidURL(url)) {
              let param = queryString.parse(window.location.search);
              param.url = url;
              param.usecache = 0;
              window.location.search = window.decodeURIComponent(queryString.stringify(param));
            } else {
              highlightURL('#mobile-url');
            }
          }}"
        >
          <i class="mt-4 material-icons pointer-events-none">refresh</i>
        </div>
      </div>
    </div>
  </header>
  <Modal enabled="{isCompareModalOpen}">
    <div
      use:clickOutside="{{ enabled: isCompareModalOpen, cb: () => {
          isCompareModalOpen = false;
          selectedCompareIndustryIndex = category;
        } }}"
      on:keydown="{e => handleKeyDown(e, 'compare', isCompareButtonEnabled)}"
      on:click="{event => event.stopPropagation()}"
      class="inline-block px-5 pt-6 pb-4 space-y-5 overflow-hidden text-left align-bottom transition-all duration-500 transform bg-white shadow-xl sm:my-8 sm:align-middle sm:max-w-xs sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      transition:fly="{{ y: 500 }}"
      aria-labelledby="modal-headline"
    >
      <h4 class="text-brand-gray">Compare your site's performance</h4>
      <div class="relative">
        <label
          class="tracking-wider text-gray-700 uppercase text-body-small"
        >url
          <!-- svelte-ignore a11y-autofocus -->
          <input
          type="text"
          id="current-site-url"
          bind:value="{initialFormUrl}"
          autofocus
          placeholder="https://twitch.tv"
          class="w-full py-2 pr-6 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
        />
          <div
            class="absolute bottom-0 right-0 flex mb-2 pointer-events-none"
            on:click="{() => {
              if (isValidURL(initialFormUrl)) {
                let param = queryString.parse(window.location.search);
                param.url = initialFormUrl;
                param.usecache = 0;
                window.location.search = window.decodeURIComponent(queryString.stringify(param));
              } else {
                highlightURL('#current-site-url');
              }
            }}"
          >
            <i
              class="cursor-pointer pointer-events-auto material-icons"
            >arrow_forward</i>
          </div>
        </label>
      </div>

      <div>
        <p class="tracking-wider text-gray-700 uppercase text-body-small">
          Industry
        </p>
        <IndustrySelect
          bind:selectedIndustryIndex="{selectedCompareIndustryIndex}"
          bind:isSelectOpen="{isCompareSelectOpen}"
        />
      </div>
      <div class="relative">
        <label
          class="tracking-wider text-gray-700 uppercase text-body-small"
        >competitor url
          <input
            type="text"
            id="comp-site-url"
            bind:value="{competitorUrl}"
            placeholder="https://mixer.com"
            class="w-full py-2 pr-6 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
          />
          <div
            class="absolute bottom-0 right-0 flex mb-2 pointer-events-none"
            on:click="{() => {
              if (isValidURL(competitorUrl)) {
                let param = queryString.parse(window.location.search);
                param.url = initialFormUrl;
                onCompareSubmit();
              } else {
                highlightURL('#comp-site-url');
              }
            }}"
          >
            <i
              class="cursor-pointer pointer-events-auto material-icons"
            >arrow_forward</i>
          </div>
        </label>
      </div>
      <button
        on:click="{onCompareSubmit}"
        disabled="{!isCompareButtonEnabled}"
        class="w-full px-5 py-2 font-extrabold tracking-wider {isCompareButtonEnabled ? 'text-white' : 'text-gray-500'}  {isCompareButtonEnabled ? 'bg-brand-red' : 'bg-gray-100'} outline-none text-label-medium"
      >Compare
      </button>
    </div>
  </Modal>
  <Modal enabled="{isTestOnMobileModalOpen}">
    <div
      use:clickOutside="{{ enabled: isTestOnMobileModalOpen, cb: () => {
          isTestOnMobileModalOpen = false;
          selectedTestIndustryIndex = category;
        } }}"
      on:keydown="{e => handleKeyDown(e, 'testmobile', isFormValid)}"
      class="inline-block px-5 pt-6 pb-4 space-y-5 overflow-visible text-left align-bottom transition-all duration-500 transform bg-white shadow-xl sm:my-8 sm:align-middle sm:max-w-xs sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      transition:fly="{{ y: 500 }}"
      aria-labelledby="modal-headline"
    >
      <h4 class="text-brand-gray">Test your site's performance on mobile</h4>
      <div class="relative">
        <label
          class="tracking-wider text-gray-700 uppercase text-body-small"
        >url
          <!-- svelte-ignore a11y-autofocus -->
          <input
          type="text"
          id="current-url"
          bind:value={initialFormUrl}
          autofocus
          placeholder="https://twitch.tv"
          class="w-full py-2 pr-6 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
        />
          <div
            class="absolute bottom-0 right-0 flex mb-2 pointer-events-none"
            on:click="{() => {
              if (isValidURL(initialFormUrl)) {
                let param = queryString.parse(window.location.search);
                param.url = initialFormUrl;
                param.usecache = 0;
                window.location.search = window.decodeURIComponent(queryString.stringify(param));
              } else {
                highlightURL('#current-url');
              }
            }}"
          >
            <i
              class="cursor-pointer pointer-events-auto material-icons"
            >arrow_forward</i>
          </div>
        </label>
      </div>

      <div>
        <p class="tracking-wider text-gray-700 uppercase text-body-small">
          Industry
        </p>
        <IndustrySelect
          bind:selectedIndustryIndex="{selectedTestIndustryIndex}"
          bind:isSelectOpen="{isTestSelectOpen}"
        />
      </div>

      <button
        on:click="{onReportSubmit}"
        disabled="{!isFormValid}"
        class="{!isFormValid ? 'bg-gray-100  md:bg-gray-700 md:opacity-25 text-gray-500' : 'bg-brand-red btn-box-shadow text-white'} w-full px-5 py-2 font-extrabold tracking-wider  outline-none text-label-medium"
      >Test Performance
      </button>
    </div>
  </Modal>
  <Modal enabled="{isDownloadModalVisible}">
    <div
      style="min-width: 22vw;max-width: 600px;"
      on:keydown="{e => handleKeyDown(e, 'report', !isArrowOpaque)}"
      use:clickOutside="{{ enabled: isDownloadModalVisible, cb: closeReportModal }}"
      class="inline-block px-5 pt-6 pb-4 space-y-5 overflow-visible text-left align-bottom transition-all duration-500 transform bg-white shadow-xl sm:my-12 sm:align-middle sm:max-w-xs sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      transition:fly="{{ y: 500 }}"
      aria-labelledby="modal-headline"
      on:click="{event => event.stopPropagation()}"
    >
      <img
        class="fixed right-0 cursor-pointer"
        style="top: -35px"
        src="/assets/images/close.svg"
        alt="close"
        on:click="{closeReportModal}"
      />
      {#if !mailSent}
        <h4 class="text-brand-gray md:text-5xl sm:text-xl">
          Get the full report
        </h4>
        <p class="text--brand-gray-700 text-body-large break-words">
          We'll send a copy of the performance report for
          {shortURL(displayUrl)}
          to your inbox
        </p>
        <div>
          <div class="relative">
            <label
              class="tracking-wider text-gray-700 uppercase text-body-large"
            >
              <!-- svelte-ignore a11y-autofocus -->
              <input
              type="email"
              bind:value="{reportEmail}"
              autofocus
              disabled={mailSending}
              placeholder="john.doe@example.com"
              class="w-full py-2 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
            />
            </label>

            <div
              class="absolute bottom-0 right-0 flex w-10 h-full py-2 border border-transparent"
            >
              <div
                class="z-10 flex items-end justify-end w-full h-full text-lg bg-white text-brand-gray"
              >
                <img
                  class="{isArrowOpaque ? 'opacity-25' : 'opacity-1'} cursor-pointer"
                  src="/assets/images/arrow-right.svg"
                  alt="submit"
                  on:click="{sendReportToEmail}"
                />
              </div>
            </div>
          </div>
        </div>
        {#if !!mailSending}
          <div
            class="fixed bottom-0 left-0 w-full bg-brand-red transition-all"
            class:sending-mail="{mailSending}"
            style="height:4px"
          ></div>
        {/if}
      {:else}
        <div class="flex items-center justify-center">
          <div>
            <h4 class="text-brand-gray md:text-5xl sm:text-xl">
              You've got mail!
            </h4>
            <p class="text--brand-gray-700 text-body-large pb-8 pt-2">
              Check your inbox for your<br />performance report.
            </p>
          </div>
          <img
            src="/assets/images/img-signup-success.svg"
            alt="mailbox"
            class="w-12 h-12"
          />
        </div>
      {/if}
    </div>
  </Modal>
  <main
    class="text-white bg-brand-gray"
    use:watchResize="{() => {
      phoneContainerMarginLeft = findPhoneContainerLeftMargin();
    }}"
  >
    <div class="w-11/12 max-w-screen-lg md:pt-6 pt-4 pb-4 mx-auto xl:w-10/12 ">
      <Header hideMobileHeader />
    </div>
    <div>
      <div
        style="top:50%;left:{phoneContainerMarginLeft}px;transform: translateY(-40%);max-width:{PHONE_CONTAINER_MAX_WIDTH}px;width:30%"
        id="phone-container"
        class="fixed float-left md:-mb-20"
      >
        <div
          class="hidden p-1 mt-4 overflow-hidden bg-white rounded-phone h-phone md:block"
          style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
        >
          <img
            class="object-contain object-top w-full h-full rounded-phone"
            src="https://image.thum.io/get/auth/15030-489357e7d4ffa46eb30fa08f26ed67c4/get/width/502/viewportWidth/502/crop/944/{appendProtocol(url)}"
            alt="Screenshot of {url}"
          />
        </div>
        <div
          class="relative z-10 hidden mt-4 md:block inverse-color-input cursor-pointer"
        >
          <Input
            url="{displayUrl}"
            class="cursor-pointer hover:opacity-50"
            on:focus="{() => (isTestOnMobileModalOpen = true)}"
          />
          <div
            class="divider absolute inset-y-0 flex pr-3 mt-2 pointer-events-none"
          >
            |
          </div>
          <div
            class="absolute inset-y-0 right-0 flex pr-3 mt-2 pointer-events-none hover:opacity-50"
            on:click="{() => {
              let param = queryString.parse(window.location.search);
              param.url = url;
              param.usecache = 0;
              window.location.search = window.decodeURIComponent(queryString.stringify(param));
            }}"
          >
            <i
              class="cursor-pointer pointer-events-auto material-icons"
            >refresh</i>
          </div>
        </div>
      </div>
      <div class="items-center md:min-h-screen md:flex">
        <div
          class="w-11/12 max-w-screen-lg pt-8 pb-4 mx-auto md:pt-0 md:pb-0 sm:pb-6 xl:w-10/12"
        >
          <div
            class="space-y-6"
            style="margin-left:{window.innerWidth >= 768 ? `${Math.min(phoneContainerMarginLeft + PHONE_CONTAINER_MAX_WIDTH, PHONE_CONTAINER_MAX_WIDTH + PHONE_CONTAINER_MARGIN_RIGHT)}px` : 0}"
          >
            <div
              class="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-between"
            >
              <div
                class="relative flex flex-col items-center px-6 bg-center bg-no-repeat md:w-3/5 md:self-start py-7"
                style="background-image:url('/assets/images/{performanceImageFile}.svg')"
              >
                <p
                  class="tracking-tight text-center text-jumbo"
                  style="letter-spacing:0em;"
                >
                  {performanceScore}
                </p>
                <div
                  class="absolute"
                  style="top:50px;right:calc(50% - 110px);z-index:21;"
                >
                  <Tooltip
                    message="Your performance score will fluctuate slightly each time you run the test. This is normal, and could be caused by changes in a number of underlying conditions, such as local network availability, internet traffic routing changes, etc."
                    width="300px"
                    height="170px"
                    direction="top-right"
                    text="text-body-small"
                  />
                </div>
                <div class="flex items-center -mt-2">
                  <div class="w-4 h-1 {performanceClass}"></div>
                  <p class="ml-2 text-base leading-relaxed">
                    {performanceReviewUnderlineClass
                      .charAt(0)
                      .toUpperCase() + performanceReviewUnderlineClass.slice(1)}
                  </p>
                </div>
              </div>
              <h2
                class="md:mr-6 font-extrabold tracking-tight text-center md:text-left md:order-first display-one"
              >
                Your Performance Score
                {!performanceMidScore ? performanceKeyword.split(' ')[0] : ''}
                {#if performanceMidScore}
                  <span
                    class="review relative overlap {performanceReviewUnderlineClass}"
                  >
                    {performanceKeyword.split(' ')[0]}</span>
                {/if}
                <span
                  class="review relative overlap {performanceReviewUnderlineClass}"
                >
                  {performanceKeyword.split(' ')[1]}</span>
              </h2>
            </div>
            <p
              class="tracking-tight text-center md:text-left text-body-large leading-mild-loose md:w-3/5 md:tracking-wide"
            >
              Understand your site’s performance, learn how it impacts your
              revenue, and find opportunities to get an even better score.
            </p>
            <div
              class="flex flex-col space-y-6 md:flex-row md:space-y-0 md:w-11/12"
            >
              <button
                on:click="{openDownloadReportModal}"
                class="px-6 py-4 font-extrabold tracking-wider border-2 shadow-md md:mr-3 bg-brand-red text-label-small border-brand-red"
              >Download the full report</button>
              <button
                on:click="{() => (isCompareModalOpen = true)}"
                class="py-4 font-extrabold tracking-wider border-2 border-gray-700 px-7 bg-brand-gray text-label-small"
              >Compare</button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white scroll-detector">
        <div
          class="w-11/12 max-w-screen-lg pt-6 pb-4 mx-auto sm:pb-6 xl:w-10/12 text-brand-gray"
        >
          <div
            style="margin-left:{window.innerWidth >= 768 ? `${Math.min(phoneContainerMarginLeft + PHONE_CONTAINER_MAX_WIDTH, PHONE_CONTAINER_MAX_WIDTH + PHONE_CONTAINER_MARGIN_RIGHT)}px` : 0}"
          >
            <section class="mt-8 md:mt-14" id="revenue-calculator">
              <h3>How can you increase your revenue?</h3>
              <p class="relative mt-2 text-body-large">
                A 1 second delay in page response can result in a 7% reduction
                in conversions. 
                <sup class="text-gray-500 citation">[1]</sup>
              </p>
              <RevenueCalculator currentPerformanceScore="{performanceScore}" />
              <p class="mt-6 text-gray-500 citation">[1] Google/SOASTA, 2017</p>
            </section>
            <aside
              class="flex p-5 mt-3 space-x-2 bg-gray-100 text-brand-gray text-body-small"
            >
              <i
                class="mt-1 text-gray-700 material-icons text-label-small"
              >lock_outline</i>
              <p>
                We do not collect any data you enter about average monthly
                visitors, conversion rate, or average order value. This
                calculator is simply a tool to help demonstrate the impact of
                performance on your bottomline.
              </p>
            </aside>
            <section class="mt-8 md:mt-12">
              <section class="space-y-4">
                <h3 id="performance-score-title">Performance Score</h3>
                <div class="relative">
                  <div class="flex items-baseline justify-between px-1 text-xs">
                    <span class="tracking-wider uppercase">
                      {protocolLessDomain(url)}
                    </span>
                    <span
                      class="font-extrabold text-black text-label-medium"
                    >{performanceScore}</span>
                  </div>
                  <div class="relative h-4 bg-gray-100">
                    <span
                      style="width:{performanceScore}%"
                      class="block {performanceClass} h-full"
                    ></span>
                    <span style="left:85%;top:5%" class="absolute w-4 h-4">
                      <img src="/assets/images/benchmark.svg" alt="benchmark" />
                    </span>
                  </div>
                </div>
                <div class="flex justify-between space-x-2 text-xs">
                  <div class="flex space-x-1">
                    <span class="w-4 h-1 mt-2 bg-error"></span>
                    <div>
                      <span class="text-brand-gray">Poor</span>
                      <p class="text-gray-700">0-39</p>
                    </div>
                  </div>
                  <div class="flex space-x-1 ">
                    <span class="w-4 h-1 mt-2 bg-warning"></span>
                    <div>
                      <span class="text-brand-gray">Needs improvement</span>
                      <p class="text-gray-700">40-85</p>
                    </div>
                  </div>
                  <div class="flex space-x-1">
                    <span class="w-4 h-1 mt-2 bg-success "></span>
                    <div>
                      <span class="text-brand-gray">Good</span>
                      <p class="text-gray-700">85-100</p>
                    </div>
                  </div>
                  <div class="flex space-x-1">
                    <span class="w-4 h-4 mt-1 mr-5px">
                      <img src="/assets/images/benchmark.svg" alt="benchmark" />
                    </span>
                    <div>
                      <span class="text-brand-gray">Benchmark</span>
                      <p class="text-gray-700">85</p>
                    </div>
                  </div>
                </div>
              </section>
              <div class="my-4">
                <p class="leading-relaxed tracking-wide">
                  This score is calculated based on 6 metrics that impact the
                  performance of your site.
                </p>
                {#if showMetrics}
                  <section class="mt-8 space-y-10">
                    {#each performanceKeyScore as { description, title, displayValue, key }}
                      <article class="flex items-start space-x-6">
                        <div
                          class="relative flex items-center justify-center flex-grow-0 flex-shrink-0 w-8 h-8 font-extrabold whitespace-no-wrap bg-gray-100 text-body-small"
                        >
                          <p>{formatNumber(displayValue)}</p>
                          <div
                            class="progress absolute {getClassName(displayValue, key)}-gradient bottom-0 left-0 w-full"
                            style="height:3px"
                          ></div>
                        </div>
                        <div>
                          <p class="font-extrabold body-text-small">{title}</p>
                          <p class="mt-1 tracking-wide">{description}</p>
                        </div>
                      </article>
                    {/each}
                  </section>
                {/if}
              </div>
              <div class="mb-4 space-y-8">
                <button
                  class="font-extrabold tracking-wider text-label-small focus:outline-none hover:outline-none hover:text-brand-red hover:opacity-80"
                  on:click="{() => {
                    showMetrics = !showMetrics;
                    const node = document.getElementById('performance-score-title');
                    scrollIntoView(node, {
                      scrollMode: 'if-needed',
                      behavior: 'smooth',
                      block: 'nearest',
                    });
                  }}"
                >
                  Show
                  {showMetrics ? 'Less' : 'More'}
                </button>
              </div>
            </section>
            <section class="mt-5 space-y-4 md:mt-11 md:w-11/12">
              <p class="leading-relaxed tracking-wide">
                Want the complete analysis?
              </p>
              <button
                on:click="{openDownloadReportModal}"
                class="w-full px-5 py-2 font-extrabold tracking-wider text-white border-2 outline-none bg-brand-red border-brand-red text-label-small md:w-auto min-w-290"
                style="box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25)"
              >Get the report</button>
            </section>
            <section class="space-y-4 mt-8 md:mt-11">
              <h3>Page Speed</h3>
              <p>
                For mobile webpages, speed is everything. Improving your load
                time by 0.1s can boost conversion rates by 8%.
              </p>
            </section>
            <section class="relative mt-4">
              <h4>
                Load Times
                <span class="text-xs font-normal">(Lower is better)</span>
              </h4>

              <PageSpeedChart
                performanceMap="{{ performanceKeyScore, pageSpeedIndexPercentage, pageSpeedClass }}"
                url="{url}"
                backgroundClass="bg-brand-gray"
                benchmarkClass=""
              />

              <PageSpeedChart
                performanceMap="{{ performanceKeyScore: [{}, { displayValue: industryAverages[category].displayValue, numericValue: industryAverages[category].average }], pageSpeedIndexPercentage: pageSpeedIndustryAveragePercentage, pageSpeedClass: 'good-gradient' }}"
                url="INDUSTRY AVERAGE"
                backgroundClass="bg-brand-gray"
                benchmarkClass=""
              />

              <div class="flex justify-between mt-4 space-x-2 text-xs">
                <div class="flex space-x-1">
                  <span class="w-4 h-1 mt-2 bg-error"></span>
                  <div>
                    <span class="text-brand-gray">Poor</span>
                    <p class="text-gray-700">Above 6.5s</p>
                  </div>
                </div>
                <div class="flex space-x-1 ">
                  <span class="w-4 h-1 mt-2 bg-warning"></span>
                  <div>
                    <span class="text-brand-gray">Needs improvement</span>
                    <p class="text-gray-700">3.5-6.5s</p>
                  </div>
                </div>
                <div class="flex space-x-1">
                  <span class="w-4 h-1 mt-2 bg-success "></span>
                  <div>
                    <span class="text-brand-gray">Good</span>
                    <p class="text-gray-700">1-3.5s</p>
                  </div>
                </div>
                <div class="flex space-x-1">
                  <span class="w-4 h-4 mt-1 mr-5px">
                    <img src="/assets/images/benchmark.svg" alt="benchmark" />
                  </span>
                  <div>
                    <span class="text-brand-gray">Benchmark</span>
                    <p class="text-gray-700">3.5s</p>
                  </div>
                </div>
              </div>
            </section>
            <section class="mt-8 md:mt-14">
              <h3>What more can you do?</h3>
              <p class="mt-2 leading-relaxed tracking-wide">
                Focus on your biggest performance roadblocks first.
              </p>
              {#each performanceOpportunities.sort((a, b) => a.score - b.score) as { title, description, score }}
                <div class="flex mt-5 space-x-4 align-baseline">
                  <i
                    class="material-icons {score < 0.85 ? 'text-error' : 'text-warning'} text-label-medium"
                  >flag</i>
                  <div class="space-y-3px">
                    <p class="font-extrabold">{title}</p>
                    <p class="text-body-small">
                      {description.split('.')[0] + '.'}
                    </p>
                  </div>
                </div>
              {/each}

              <!-- <p class="mt-5 mb-3">Boost your performance</p>
              <button
                class="w-full px-5 py-2 font-extrabold tracking-wider text-white border-2 outline-none bg-brand-red border-brand-red text-label-small md:w-auto min-w-290"
                style="box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25)"
                on:click="{openDownloadReportModal}"
              >Download the full report</button> -->
            </section>
            <section class="mt-8 space-y-4 md:mt-14 md:w-11/12">
              <h3 id="where-do-you-stand">Where do you stand?</h3>
              <p class="leading-relaxed tracking-wide">
                How does your website perform in comparison to others? Compare
                with your competitors to find out.
              </p>
              <button
                class="w-full md:w-auto px-5 py-2 font-extrabold tracking-wider text-gray-800 border-2 border-gray-300 outline-none text-label-small"
                on:click="{() => (isCompareModalOpen = true)}"
              >Compare now</button>
            </section>
            <section class="mt-8 space-y-4 md:mt-15 md:w-11/12">
              <h3>Get the complete analysis.</h3>
              <p class="leading-relaxed tracking-wide">
                Get a detailed report of your website’s performance, and tips
                and tricks for improving it.
              </p>
              <button
                class="w-full md:w-auto px-5 py-2 font-extrabold tracking-wider text-white border-2 outline-none bg-brand-red border-brand-red text-label-small min-w-290"
                style="box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25)"
                on:click="{openDownloadReportModal}"
              >Download the full report</button>
              <button
                class="w-full md:w-auto px-5 py-2 font-extrabold tracking-wider text-gray-800 border-2 border-gray-300 outline-none text-label-small"
                on:click="{() => (isTestOnMobileModalOpen = true)}"
              >Run a new test</button>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
{/if}
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TB2K5G6"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
