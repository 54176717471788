export default function currentElementPosition(detector, inversedInput) {
  if (!detector || !inversedInput) return;

  let fixed_position = inversedInput.getBoundingClientRect().top;
  let fixed_height = inversedInput.offsetHeight;

  let toCross_position = detector.getBoundingClientRect().top;
  if (fixed_position + fixed_height < toCross_position) {
    inversedInput.classList.remove("text-brand-gray");
    inversedInput.classList.add("text-gray-100");
  } else {
    inversedInput.classList.remove("text-gray-100");
    inversedInput.classList.add("text-brand-gray");
  }
}
