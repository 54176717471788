<script>
  export let performanceMap, url, backgroundClass, benchmarkClass;
</script>

<div>
  <div class="flex justify-between text-xs px-1 items-baseline relative mt-2">
    <span
      class="uppercase tracking-wider"
    >{url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}
    </span>
    <span
      class="text-label-small text-black font-extrabold"
    >{performanceMap.performanceKeyScore[1].displayValue}</span>
  </div>
  <div class="bg-gray-100 h-4 relative">
    <span
      class="block h-full absolute {performanceMap.pageSpeedClass}"
      style="width:{(performanceMap.performanceKeyScore[1].numericValue / 1000).toFixed(1) * 10}%"
    ></span>

    <span style="left:35%;" class="h-4 w-4 absolute {benchmarkClass}">
      <img src="/assets/images/benchmark.svg" alt="benchmark" />
    </span>
  </div>
</div>
