const immutableSpeedAndDropRates = [
  {
    drop: 0.0,
    time: 0.0,
  },
  {
    drop: 0.0,
    time: 0.1,
  },
  {
    drop: 0.981042817970934,
    time: 1.0,
  },
  {
    drop: 0.11912130528311,
    time: 10.0,
  },
  {
    drop: 0.118075252234284,
    time: 10.1,
  },
  {
    drop: 0.117048774324502,
    time: 10.2,
  },
  {
    drop: 0.116041316654347,
    time: 10.3,
  },
  {
    drop: 0.115052345284816,
    time: 10.4,
  },
  {
    drop: 0.114081346252326,
    time: 10.5,
  },
  {
    drop: 0.113127824638148,
    time: 10.6,
  },
  {
    drop: 0.112191303690445,
    time: 10.7,
  },
  {
    drop: 0.111271323993689,
    time: 10.8,
  },
  {
    drop: 0.110367442684023,
    time: 10.9,
  },
  {
    drop: 0.895407572941087,
    time: 1.1,
  },
  {
    drop: 0.109479232706811,
    time: 11.0,
  },
  {
    drop: 0.108606282113732,
    time: 11.1,
  },
  {
    drop: 0.107748193397428,
    time: 11.2,
  },
  {
    drop: 0.106904582861045,
    time: 11.3,
  },
  {
    drop: 0.106075080020518,
    time: 11.4,
  },
  {
    drop: 0.105259327037843,
    time: 11.5,
  },
  {
    drop: 0.104456978183443,
    time: 11.6,
  },
  {
    drop: 0.103667699325527,
    time: 11.7,
  },
  {
    drop: 0.10289116744544,
    time: 11.8,
  },
  {
    drop: 0.102127070177205,
    time: 11.9,
  },
  {
    drop: 0.824180273547115,
    time: 1.2,
  },
  {
    drop: 0.101375105369561,
    time: 12.0,
  },
  {
    drop: 0.100634980669789,
    time: 12.1,
  },
  {
    drop: 0.099906413127626,
    time: 12.2,
  },
  {
    drop: 0.099189128818455,
    time: 12.3,
  },
  {
    drop: 0.098482862484284,
    time: 12.4,
  },
  {
    drop: 0.097787357192036,
    time: 12.5,
  },
  {
    drop: 0.09710236400784,
    time: 12.6,
  },
  {
    drop: 0.096427641686425,
    time: 12.7,
  },
  {
    drop: 0.095762956375212,
    time: 12.8,
  },
  {
    drop: 0.095108081331552,
    time: 12.9,
  },
  {
    drop: 0.763964524001981,
    time: 1.3,
  },
  {
    drop: 0.094462796653572,
    time: 13.0,
  },
  {
    drop: 0.093826889022286,
    time: 13.1,
  },
  {
    drop: 0.093200151456352,
    time: 13.2,
  },
  {
    drop: 0.092582383076856,
    time: 13.3,
  },
  {
    drop: 0.091973388882982,
    time: 13.4,
  },
  {
    drop: 0.091372979537585,
    time: 13.5,
  },
  {
    drop: 0.090780971161819,
    time: 13.6,
  },
  {
    drop: 0.090197185138985,
    time: 13.7,
  },
  {
    drop: 0.08962144792648,
    time: 13.8,
  },
  {
    drop: 0.089053590876076,
    time: 13.9,
  },
  {
    drop: 0.712359335465007,
    time: 1.4,
  },
  {
    drop: 0.088493450061677,
    time: 14.0,
  },
  {
    drop: 0.0879408661143,
    time: 14.1,
  },
  {
    drop: 0.087395684063951,
    time: 14.2,
  },
  {
    drop: 0.086857753188174,
    time: 14.3,
  },
  {
    drop: 0.086326926866764,
    time: 14.4,
  },
  {
    drop: 0.085803062442236,
    time: 14.5,
  },
  {
    drop: 0.085286021086417,
    time: 14.6,
  },
  {
    drop: 0.084775667671866,
    time: 14.7,
  },
  {
    drop: 0.084271870649011,
    time: 14.8,
  },
  {
    drop: 0.083774501927647,
    time: 14.9,
  },
  {
    drop: 0.667618257144942,
    time: 1.5,
  },
  {
    drop: 0.083283436763448,
    time: 15.0,
  },
  {
    drop: 0.082798553648966,
    time: 15.1,
  },
  {
    drop: 0.08231973420858,
    time: 15.2,
  },
  {
    drop: 0.081846863097845,
    time: 15.3,
  },
  {
    drop: 0.081379827906458,
    time: 15.4,
  },
  {
    drop: 0.080918519065265,
    time: 15.5,
  },
  {
    drop: 0.080462829756267,
    time: 15.6,
  },
  {
    drop: 0.080012655826645,
    time: 15.7,
  },
  {
    drop: 0.079567895705557,
    time: 15.8,
  },
  {
    drop: 0.079128450324295,
    time: 15.9,
  },
  {
    drop: 0.628439874320175,
    time: 1.6,
  },
  {
    drop: 0.078694223039166,
    time: 16.0,
  },
  {
    drop: 0.078265119557508,
    time: 16.1,
  },
  {
    drop: 0.077841047866101,
    time: 16.2,
  },
  {
    drop: 0.077421918162401,
    time: 16.3,
  },
  {
    drop: 0.077007642788285,
    time: 16.4,
  },
  {
    drop: 0.076598136165873,
    time: 16.5,
  },
  {
    drop: 0.076193314736073,
    time: 16.6,
  },
  {
    drop: 0.075793096898891,
    time: 16.7,
  },
  {
    drop: 0.075397402956257,
    time: 16.8,
  },
  {
    drop: 0.075006155056312,
    time: 16.9,
  },
  {
    drop: 0.593834092773271,
    time: 1.7,
  },
  {
    drop: 0.074619277140409,
    time: 17.0,
  },
  {
    drop: 0.074236694891083,
    time: 17.1,
  },
  {
    drop: 0.073858335682497,
    time: 17.2,
  },
  {
    drop: 0.073484128532275,
    time: 17.3,
  },
  {
    drop: 0.073114004054919,
    time: 17.4,
  },
  {
    drop: 0.072747894417113,
    time: 17.5,
  },
  {
    drop: 0.072385733294089,
    time: 17.6,
  },
  {
    drop: 0.072027455827883,
    time: 17.7,
  },
  {
    drop: 0.071672998586725,
    time: 17.8,
  },
  {
    drop: 0.071322299525822,
    time: 17.9,
  },
  {
    drop: 0.563033999488694,
    time: 1.8,
  },
  {
    drop: 0.070975297949505,
    time: 18.0,
  },
  {
    drop: 0.070631934474474,
    time: 18.1,
  },
  {
    drop: 0.070292150994389,
    time: 18.2,
  },
  {
    drop: 0.069955890645601,
    time: 18.3,
  },
  {
    drop: 0.069623097773741,
    time: 18.4,
  },
  {
    drop: 0.069293717901764,
    time: 18.5,
  },
  {
    drop: 0.06896769769873,
    time: 18.6,
  },
  {
    drop: 0.068644984949651,
    time: 18.7,
  },
  {
    drop: 0.068325528526292,
    time: 18.8,
  },
  {
    drop: 0.068009278358927,
    time: 18.9,
  },
  {
    drop: 0.535436124784261,
    time: 1.9,
  },
  {
    drop: 0.067696185408939,
    time: 19.0,
  },
  {
    drop: 0.067386201642194,
    time: 19.1,
  },
  {
    drop: 0.067079280003379,
    time: 19.2,
  },
  {
    drop: 0.066775374391104,
    time: 19.3,
  },
  {
    drop: 0.066474439633568,
    time: 19.4,
  },
  {
    drop: 0.066176431465292,
    time: 19.5,
  },
  {
    drop: 0.065881306504267,
    time: 19.6,
  },
  {
    drop: 0.065589022229906,
    time: 19.7,
  },
  {
    drop: 0.065299536961749,
    time: 19.8,
  },
  {
    drop: 0.065012809838549,
    time: 19.9,
  },
  {
    drop: 0.0,
    time: 0.2,
  },
  {
    drop: 0.5105589575638,
    time: 2.0,
  },
  {
    drop: 0.064728800798286,
    time: 20.0,
  },
  {
    drop: 0.064447470558515,
    time: 20.1,
  },
  {
    drop: 0.064168780597407,
    time: 20.2,
  },
  {
    drop: 0.063892693135359,
    time: 20.3,
  },
  {
    drop: 0.063619171117102,
    time: 20.4,
  },
  {
    drop: 0.063348178194289,
    time: 20.5,
  },
  {
    drop: 0.063079678708644,
    time: 20.6,
  },
  {
    drop: 0.062813637675618,
    time: 20.7,
  },
  {
    drop: 0.062550020768382,
    time: 20.8,
  },
  {
    drop: 0.062288794302456,
    time: 20.9,
  },
  {
    drop: 0.488013512658503,
    time: 2.1,
  },
  {
    drop: 0.062029925220553,
    time: 21.0,
  },
  {
    drop: 0.061773381078067,
    time: 21.1,
  },
  {
    drop: 0.061519130028831,
    time: 21.2,
  },
  {
    drop: 0.061267140811316,
    time: 21.3,
  },
  {
    drop: 0.06101738273514,
    time: 21.4,
  },
  {
    drop: 0.060769825668139,
    time: 21.5,
  },
  {
    drop: 0.060524440023535,
    time: 21.6,
  },
  {
    drop: 0.060281196747636,
    time: 21.7,
  },
  {
    drop: 0.060040067307865,
    time: 21.8,
  },
  {
    drop: 0.05980102368095,
    time: 21.9,
  },
  {
    drop: 0.46748204795084,
    time: 2.2,
  },
  {
    drop: 0.059564038341664,
    time: 22.0,
  },
  {
    drop: 0.05932908425168,
    time: 22.1,
  },
  {
    drop: 0.059096134848802,
    time: 22.2,
  },
  {
    drop: 0.058865164036526,
    time: 22.3,
  },
  {
    drop: 0.058636146173696,
    time: 22.4,
  },
  {
    drop: 0.058409056064734,
    time: 22.5,
  },
  {
    drop: 0.058183868949735,
    time: 22.6,
  },
  {
    drop: 0.057960560495318,
    time: 22.7,
  },
  {
    drop: 0.057739106785126,
    time: 22.8,
  },
  {
    drop: 0.057519484311119,
    time: 22.9,
  },
  {
    drop: 0.448702410715285,
    time: 2.3,
  },
  {
    drop: 0.05730166996476,
    time: 23.0,
  },
  {
    drop: 0.057085641028354,
    time: 23.1,
  },
  {
    drop: 0.056871375167189,
    time: 23.2,
  },
  {
    drop: 0.056658850420988,
    time: 23.3,
  },
  {
    drop: 0.056448045196356,
    time: 23.4,
  },
  {
    drop: 0.056238938258968,
    time: 23.5,
  },
  {
    drop: 0.056031508726195,
    time: 23.6,
  },
  {
    drop: 0.055825736059689,
    time: 23.7,
  },
  {
    drop: 0.055621600058409,
    time: 23.8,
  },
  {
    drop: 0.05541908085156,
    time: 23.9,
  },
  {
    drop: 0.431456346817427,
    time: 2.4,
  },
  {
    drop: 0.055218158891934,
    time: 24.0,
  },
  {
    drop: 0.05501881494927,
    time: 24.1,
  },
  {
    drop: 0.054821030103791,
    time: 24.2,
  },
  {
    drop: 0.054624785739965,
    time: 24.3,
  },
  {
    drop: 0.054430063540467,
    time: 24.4,
  },
  {
    drop: 0.05423684547996,
    time: 24.5,
  },
  {
    drop: 0.054045113819523,
    time: 24.6,
  },
  {
    drop: 0.053854851100765,
    time: 24.7,
  },
  {
    drop: 0.053666040140432,
    time: 24.8,
  },
  {
    drop: 0.053478664024809,
    time: 24.9,
  },
  {
    drop: 0.415560647910862,
    time: 2.5,
  },
  {
    drop: 0.053292706104569,
    time: 25.0,
  },
  {
    drop: 0.053108149989511,
    time: 25.1,
  },
  {
    drop: 0.052924979543523,
    time: 25.2,
  },
  {
    drop: 0.052743178879733,
    time: 25.3,
  },
  {
    drop: 0.052562732355521,
    time: 25.4,
  },
  {
    drop: 0.052383624567987,
    time: 25.5,
  },
  {
    drop: 0.05220584034924,
    time: 25.6,
  },
  {
    drop: 0.052029364761985,
    time: 25.7,
  },
  {
    drop: 0.051854183095057,
    time: 25.8,
  },
  {
    drop: 0.051680280859157,
    time: 25.9,
  },
  {
    drop: 0.400860362867239,
    time: 2.6,
  },
  {
    drop: 0.051507643782722,
    time: 26.0,
  },
  {
    drop: 0.051336257807799,
    time: 26.1,
  },
  {
    drop: 0.051166109085887,
    time: 26.2,
  },
  {
    drop: 0.0509971839743,
    time: 26.3,
  },
  {
    drop: 0.050829469032188,
    time: 26.4,
  },
  {
    drop: 0.0506629510167,
    time: 26.5,
  },
  {
    drop: 0.050497616879541,
    time: 26.6,
  },
  {
    drop: 0.050333453763263,
    time: 26.7,
  },
  {
    drop: 0.050170448997799,
    time: 26.8,
  },
  {
    drop: 0.050008590097006,
    time: 26.9,
  },
  {
    drop: 0.387223532012859,
    time: 2.7,
  },
  {
    drop: 0.04984786475537,
    time: 27.0,
  },
  {
    drop: 0.049688260844727,
    time: 27.1,
  },
  {
    drop: 0.049529766411061,
    time: 27.2,
  },
  {
    drop: 0.04937236967133,
    time: 27.3,
  },
  {
    drop: 0.049216059010404,
    time: 27.4,
  },
  {
    drop: 0.049060822978198,
    time: 27.5,
  },
  {
    drop: 0.04890665028654,
    time: 27.6,
  },
  {
    drop: 0.048753529806422,
    time: 27.7,
  },
  {
    drop: 0.048601450565178,
    time: 27.8,
  },
  {
    drop: 0.048450401743705,
    time: 27.9,
  },
  {
    drop: 0.374537059367097,
    time: 2.8,
  },
  {
    drop: 0.048300372673782,
    time: 28.0,
  },
  {
    drop: 0.048151352835424,
    time: 28.1,
  },
  {
    drop: 0.048003331854329,
    time: 28.2,
  },
  {
    drop: 0.047856299499305,
    time: 28.3,
  },
  {
    drop: 0.047710245679911,
    time: 28.4,
  },
  {
    drop: 0.047565160443774,
    time: 28.5,
  },
  {
    drop: 0.047421033974548,
    time: 28.6,
  },
  {
    drop: 0.047277856589423,
    time: 28.7,
  },
  {
    drop: 0.047135618736705,
    time: 28.8,
  },
  {
    drop: 0.046994310993909,
    time: 28.9,
  },
  {
    drop: 0.362703445466828,
    time: 2.9,
  },
  {
    drop: 0.04685392406536,
    time: 29.0,
  },
  {
    drop: 0.046714448779972,
    time: 29.1,
  },
  {
    drop: 0.046575876089494,
    time: 29.2,
  },
  {
    drop: 0.046438197066046,
    time: 29.3,
  },
  {
    drop: 0.046301402900406,
    time: 29.4,
  },
  {
    drop: 0.046165484899885,
    time: 29.5,
  },
  {
    drop: 0.04603043448641,
    time: 29.6,
  },
  {
    drop: 0.045896243194754,
    time: 29.7,
  },
  {
    drop: 0.04576290267051,
    time: 29.8,
  },
  {
    drop: 0.045630404668362,
    time: 29.9,
  },
  {
    drop: 0.0,
    time: 0.3,
  },
  {
    drop: 0.35163817813697,
    time: 3.0,
  },
  {
    drop: 0.045498741050265,
    time: 30.0,
  },
  {
    drop: 0.045367903783711,
    time: 30.1,
  },
  {
    drop: 0.045237884940019,
    time: 30.2,
  },
  {
    drop: 0.045108676692696,
    time: 30.3,
  },
  {
    drop: 0.044980271315698,
    time: 30.4,
  },
  {
    drop: 0.044852661181838,
    time: 30.5,
  },
  {
    drop: 0.04472583876125,
    time: 30.6,
  },
  {
    drop: 0.044599796619793,
    time: 30.7,
  },
  {
    drop: 0.044474527417537,
    time: 30.8,
  },
  {
    drop: 0.044350023907236,
    time: 30.9,
  },
  {
    drop: 0.341267631391506,
    time: 3.1,
  },
  {
    drop: 0.044226278932946,
    time: 31.0,
  },
  {
    drop: 0.044103285428476,
    time: 31.1,
  },
  {
    drop: 0.043981036416074,
    time: 31.2,
  },
  {
    drop: 0.043859525004919,
    time: 31.3,
  },
  {
    drop: 0.043738744389898,
    time: 31.4,
  },
  {
    drop: 0.043618687850233,
    time: 31.5,
  },
  {
    drop: 0.043499348748077,
    time: 31.6,
  },
  {
    drop: 0.04338072052732,
    time: 31.7,
  },
  {
    drop: 0.04326279671234,
    time: 31.8,
  },
  {
    drop: 0.043145570906766,
    time: 31.9,
  },
  {
    drop: 0.331527360452788,
    time: 3.2,
  },
  {
    drop: 0.043029036792097,
    time: 32.0,
  },
  {
    drop: 0.042913188126792,
    time: 32.1,
  },
  {
    drop: 0.042798018744872,
    time: 32.2,
  },
  {
    drop: 0.042683522554876,
    time: 32.3,
  },
  {
    drop: 0.042569693538686,
    time: 32.4,
  },
  {
    drop: 0.042456525750456,
    time: 32.5,
  },
  {
    drop: 0.042344013315554,
    time: 32.6,
  },
  {
    drop: 0.042232150429333,
    time: 32.7,
  },
  {
    drop: 0.042120931356293,
    time: 32.8,
  },
  {
    drop: 0.042010350428967,
    time: 32.9,
  },
  {
    drop: 0.322360708270053,
    time: 3.3,
  },
  {
    drop: 0.041900402046835,
    time: 33.0,
  },
  {
    drop: 0.041791080675435,
    time: 33.1,
  },
  {
    drop: 0.041682380845343,
    time: 33.2,
  },
  {
    drop: 0.041574297151192,
    time: 33.3,
  },
  {
    drop: 0.041466824250835,
    time: 33.4,
  },
  {
    drop: 0.041359956864229,
    time: 33.5,
  },
  {
    drop: 0.041253689772725,
    time: 33.6,
  },
  {
    drop: 0.041148017818093,
    time: 33.7,
  },
  {
    drop: 0.04104293590157,
    time: 33.8,
  },
  {
    drop: 0.04093843898314,
    time: 33.9,
  },
  {
    drop: 0.313717658990442,
    time: 3.4,
  },
  {
    drop: 0.040834522080613,
    time: 34.0,
  },
  {
    drop: 0.040731180268772,
    time: 34.1,
  },
  {
    drop: 0.040628408678622,
    time: 34.2,
  },
  {
    drop: 0.040526202496438,
    time: 34.3,
  },
  {
    drop: 0.04042455696327,
    time: 34.4,
  },
  {
    drop: 0.040323467373837,
    time: 34.5,
  },
  {
    drop: 0.040222929075928,
    time: 34.6,
  },
  {
    drop: 0.040122937469667,
    time: 34.7,
  },
  {
    drop: 0.040023488006713,
    time: 34.8,
  },
  {
    drop: 0.039924576189598,
    time: 34.9,
  },
  {
    drop: 0.305553888700083,
    time: 3.5,
  },
  {
    drop: 0.039826197570969,
    time: 35.0,
  },
  {
    drop: 0.03972834775281,
    time: 35.1,
  },
  {
    drop: 0.039631022386044,
    time: 35.2,
  },
  {
    drop: 0.039534217169401,
    time: 35.3,
  },
  {
    drop: 0.039437927849217,
    time: 35.4,
  },
  {
    drop: 0.039342150218436,
    time: 35.5,
  },
  {
    drop: 0.039246880116162,
    time: 35.6,
  },
  {
    drop: 0.039152113426932,
    time: 35.7,
  },
  {
    drop: 0.039057846080115,
    time: 35.8,
  },
  {
    drop: 0.03896407404933,
    time: 35.9,
  },
  {
    drop: 0.297829974872377,
    time: 3.6,
  },
  {
    drop: 0.038870793351808,
    time: 36.0,
  },
  {
    drop: 0.038778000047812,
    time: 36.1,
  },
  {
    drop: 0.038685690240015,
    time: 36.2,
  },
  {
    drop: 0.038593860073077,
    time: 36.3,
  },
  {
    drop: 0.038502505732807,
    time: 36.4,
  },
  {
    drop: 0.038411623445889,
    time: 36.5,
  },
  {
    drop: 0.038321209479197,
    time: 36.6,
  },
  {
    drop: 0.038231260139199,
    time: 36.7,
  },
  {
    drop: 0.038141771771616,
    time: 36.8,
  },
  {
    drop: 0.038052740760719,
    time: 36.9,
  },
  {
    drop: 0.290510734350957,
    time: 3.7,
  },
  {
    drop: 0.037964163528947,
    time: 37.0,
  },
  {
    drop: 0.037876036536244,
    time: 37.1,
  },
  {
    drop: 0.037788356279722,
    time: 37.2,
  },
  {
    drop: 0.037701119293221,
    time: 37.3,
  },
  {
    drop: 0.037614322146506,
    time: 37.4,
  },
  {
    drop: 0.037527961445205,
    time: 37.5,
  },
  {
    drop: 0.037442033830049,
    time: 37.6,
  },
  {
    drop: 0.037356535976563,
    time: 37.7,
  },
  {
    drop: 0.037271464594513,
    time: 37.8,
  },
  {
    drop: 0.037186816427592,
    time: 37.9,
  },
  {
    drop: 0.283564666087144,
    time: 3.8,
  },
  {
    drop: 0.037102588252846,
    time: 38.0,
  },
  {
    drop: 0.03701877688036,
    time: 38.1,
  },
  {
    drop: 0.036935379152681,
    time: 38.2,
  },
  {
    drop: 0.036852391944642,
    time: 38.3,
  },
  {
    drop: 0.036769812162674,
    time: 38.4,
  },
  {
    drop: 0.03668763674467,
    time: 38.5,
  },
  {
    drop: 0.036605862659345,
    time: 38.6,
  },
  {
    drop: 0.036524486906009,
    time: 38.7,
  },
  {
    drop: 0.036443506514106,
    time: 38.8,
  },
  {
    drop: 0.036362918542898,
    time: 38.9,
  },
  {
    drop: 0.276963479755876,
    time: 3.9,
  },
  {
    drop: 0.03628272008096,
    time: 39.0,
  },
  {
    drop: 0.036202908245997,
    time: 39.1,
  },
  {
    drop: 0.036123480184314,
    time: 39.2,
  },
  {
    drop: 0.036044433070526,
    time: 39.3,
  },
  {
    drop: 0.035965764107293,
    time: 39.4,
  },
  {
    drop: 0.035887470524743,
    time: 39.5,
  },
  {
    drop: 0.035809549580423,
    time: 39.6,
  },
  {
    drop: 0.035731998558664,
    time: 39.7,
  },
  {
    drop: 0.035654814770592,
    time: 39.8,
  },
  {
    drop: 0.03557799555336,
    time: 39.9,
  },
  {
    drop: 0.0,
    time: 0.4,
  },
  {
    drop: 0.270681695168507,
    time: 4.0,
  },
  {
    drop: 0.0355015382703,
    time: 40.0,
  },
  {
    drop: 0.035425440310233,
    time: 40.1,
  },
  {
    drop: 0.035349699087361,
    time: 40.2,
  },
  {
    drop: 0.035274312040912,
    time: 40.3,
  },
  {
    drop: 0.035199276634756,
    time: 40.4,
  },
  {
    drop: 0.035124590357238,
    time: 40.5,
  },
  {
    drop: 0.035050250720836,
    time: 40.6,
  },
  {
    drop: 0.034976255261787,
    time: 40.7,
  },
  {
    drop: 0.034902601539888,
    time: 40.8,
  },
  {
    drop: 0.034829287138249,
    time: 40.9,
  },
  {
    drop: 0.26469630035848,
    time: 4.1,
  },
  {
    drop: 0.034756309662853,
    time: 41.0,
  },
  {
    drop: 0.034683666742508,
    time: 41.1,
  },
  {
    drop: 0.034611356028358,
    time: 41.2,
  },
  {
    drop: 0.034539375193843,
    time: 41.3,
  },
  {
    drop: 0.034467721934095,
    time: 41.4,
  },
  {
    drop: 0.034396393966119,
    time: 41.5,
  },
  {
    drop: 0.034325389028167,
    time: 41.6,
  },
  {
    drop: 0.034254704879722,
    time: 41.7,
  },
  {
    drop: 0.034184339301091,
    time: 41.8,
  },
  {
    drop: 0.034114290093235,
    time: 41.9,
  },
  {
    drop: 0.258986458534793,
    time: 4.2,
  },
  {
    drop: 0.034044555077606,
    time: 42.0,
  },
  {
    drop: 0.033975132095709,
    time: 42.1,
  },
  {
    drop: 0.0339060190091,
    time: 42.2,
  },
  {
    drop: 0.033837213698895,
    time: 42.3,
  },
  {
    drop: 0.033768714065863,
    time: 42.4,
  },
  {
    drop: 0.033700518029844,
    time: 42.5,
  },
  {
    drop: 0.033632623529911,
    time: 42.6,
  },
  {
    drop: 0.033565028523763,
    time: 42.7,
  },
  {
    drop: 0.033497730987753,
    time: 42.8,
  },
  {
    drop: 0.033430728916684,
    time: 42.9,
  },
  {
    drop: 0.253533255929095,
    time: 4.3,
  },
  {
    drop: 0.033364020323456,
    time: 43.0,
  },
  {
    drop: 0.033297603239002,
    time: 43.1,
  },
  {
    drop: 0.033231475711948,
    time: 43.2,
  },
  {
    drop: 0.03316563580853,
    time: 43.3,
  },
  {
    drop: 0.033100081612303,
    time: 43.4,
  },
  {
    drop: 0.03303481122412,
    time: 43.5,
  },
  {
    drop: 0.032969822761619,
    time: 43.6,
  },
  {
    drop: 0.032905114359361,
    time: 43.7,
  },
  {
    drop: 0.032840684168445,
    time: 43.8,
  },
  {
    drop: 0.032776530356427,
    time: 43.9,
  },
  {
    drop: 0.248319484018111,
    time: 4.4,
  },
  {
    drop: 0.032712651107092,
    time: 44.0,
  },
  {
    drop: 0.032649044620148,
    time: 44.1,
  },
  {
    drop: 0.032585709111355,
    time: 44.2,
  },
  {
    drop: 0.032522642812127,
    time: 44.3,
  },
  {
    drop: 0.032459843969246,
    time: 44.4,
  },
  {
    drop: 0.032397310845078,
    time: 44.5,
  },
  {
    drop: 0.03233504171698,
    time: 44.6,
  },
  {
    drop: 0.032273034877473,
    time: 44.7,
  },
  {
    drop: 0.032211288633777,
    time: 44.8,
  },
  {
    drop: 0.032149801307924,
    time: 44.9,
  },
  {
    drop: 0.243329450766017,
    time: 4.5,
  },
  {
    drop: 0.032088571236444,
    time: 45.0,
  },
  {
    drop: 0.032027596770212,
    time: 45.1,
  },
  {
    drop: 0.031966876274381,
    time: 45.2,
  },
  {
    drop: 0.031906408128046,
    time: 45.3,
  },
  {
    drop: 0.031846190724383,
    time: 45.4,
  },
  {
    drop: 0.031786222470176,
    time: 45.5,
  },
  {
    drop: 0.031726501785889,
    time: 45.6,
  },
  {
    drop: 0.031667027105464,
    time: 45.7,
  },
  {
    drop: 0.031607796876165,
    time: 45.8,
  },
  {
    drop: 0.031548809558379,
    time: 45.9,
  },
  {
    drop: 0.238548816463768,
    time: 4.6,
  },
  {
    drop: 0.031490063625572,
    time: 46.0,
  },
  {
    drop: 0.031431557564177,
    time: 46.1,
  },
  {
    drop: 0.031373289873282,
    time: 46.2,
  },
  {
    drop: 0.031315259064635,
    time: 46.3,
  },
  {
    drop: 0.031257463662548,
    time: 46.4,
  },
  {
    drop: 0.031199902203616,
    time: 46.5,
  },
  {
    drop: 0.031142573236687,
    time: 46.6,
  },
  {
    drop: 0.031085475322779,
    time: 46.7,
  },
  {
    drop: 0.031028607034789,
    time: 46.8,
  },
  {
    drop: 0.030971966957538,
    time: 46.9,
  },
  {
    drop: 0.233964450499391,
    time: 4.7,
  },
  {
    drop: 0.030915553687572,
    time: 47.0,
  },
  {
    drop: 0.030859365833003,
    time: 47.1,
  },
  {
    drop: 0.030803402013446,
    time: 47.2,
  },
  {
    drop: 0.030747660859976,
    time: 47.3,
  },
  {
    drop: 0.030692141014743,
    time: 47.4,
  },
  {
    drop: 0.030636841131226,
    time: 47.5,
  },
  {
    drop: 0.030581759873738,
    time: 47.6,
  },
  {
    drop: 0.030526895917671,
    time: 47.7,
  },
  {
    drop: 0.030472247949098,
    time: 47.8,
  },
  {
    drop: 0.030417814664818,
    time: 47.9,
  },
  {
    drop: 0.229564306004382,
    time: 4.8,
  },
  {
    drop: 0.03036359477222,
    time: 48.0,
  },
  {
    drop: 0.030309586989108,
    time: 48.1,
  },
  {
    drop: 0.030255790043743,
    time: 48.2,
  },
  {
    drop: 0.030202202674512,
    time: 48.3,
  },
  {
    drop: 0.030148823630105,
    time: 48.4,
  },
  {
    drop: 0.030095651669115,
    time: 48.5,
  },
  {
    drop: 0.030042685560194,
    time: 48.6,
  },
  {
    drop: 0.029989924081786,
    time: 48.7,
  },
  {
    drop: 0.029937366022148,
    time: 48.8,
  },
  {
    drop: 0.029885010179087,
    time: 48.9,
  },
  {
    drop: 0.225337309821172,
    time: 4.9,
  },
  {
    drop: 0.029832855360068,
    time: 49.0,
  },
  {
    drop: 0.029780900381971,
    time: 49.1,
  },
  {
    drop: 0.029729144071045,
    time: 49.2,
  },
  {
    drop: 0.029677585262755,
    time: 49.3,
  },
  {
    drop: 0.029626222801893,
    time: 49.4,
  },
  {
    drop: 0.029575055542219,
    time: 49.5,
  },
  {
    drop: 0.02952408234651,
    time: 49.6,
  },
  {
    drop: 0.029473302086513,
    time: 49.7,
  },
  {
    drop: 0.029422713642724,
    time: 49.8,
  },
  {
    drop: 0.02937231590443,
    time: 49.9,
  },
  {
    drop: 0.0,
    time: 0.5,
  },
  {
    drop: 0.221273265646937,
    time: 5.0,
  },
  {
    drop: 0.02932210776958,
    time: 50.0,
  },
  {
    drop: 0.217362768544982,
    time: 5.1,
  },
  {
    drop: 0.213597129294474,
    time: 5.2,
  },
  {
    drop: 0.209968307279484,
    time: 5.3,
  },
  {
    drop: 0.206468850811903,
    time: 5.4,
  },
  {
    drop: 0.20309184394256,
    time: 5.5,
  },
  {
    drop: 0.199830858950989,
    time: 5.6,
  },
  {
    drop: 0.196679913817333,
    time: 5.7,
  },
  {
    drop: 0.193633434076212,
    time: 5.8,
  },
  {
    drop: 0.190686218533709,
    time: 5.9,
  },
  {
    drop: 1.62056827105765,
    time: 0.6,
  },
  {
    drop: 0.187833408397475,
    time: 6.0,
  },
  {
    drop: 0.185070459429504,
    time: 6.1,
  },
  {
    drop: 0.182393116781077,
    time: 6.2,
  },
  {
    drop: 0.179797392212278,
    time: 6.3,
  },
  {
    drop: 0.177279543436826,
    time: 6.4,
  },
  {
    drop: 0.174836055362948,
    time: 6.5,
  },
  {
    drop: 0.172463623030827,
    time: 6.6,
  },
  {
    drop: 0.170159136069303,
    time: 6.7,
  },
  {
    drop: 0.167919664516214,
    time: 6.8,
  },
  {
    drop: 0.165742445864647,
    time: 6.9,
  },
  {
    drop: 1.38920543943462,
    time: 0.7,
  },
  {
    drop: 0.163624873212576,
    time: 7.0,
  },
  {
    drop: 0.161564484408117,
    time: 7.1,
  },
  {
    drop: 0.159558952093297,
    time: 7.2,
  },
  {
    drop: 0.157606074561101,
    time: 7.3,
  },
  {
    drop: 0.155703767348991,
    time: 7.4,
  },
  {
    drop: 0.153850055500371,
    time: 7.5,
  },
  {
    drop: 0.152043066432728,
    time: 7.6,
  },
  {
    drop: 0.150281023358012,
    time: 7.7,
  },
  {
    drop: 0.148562239205141,
    time: 7.8,
  },
  {
    drop: 0.14688511100116,
    time: 7.9,
  },
  {
    drop: 1.21804221945776,
    time: 0.8,
  },
  {
    drop: 0.145248114670427,
    time: 8.0,
  },
  {
    drop: 0.143649800216483,
    time: 8.1,
  },
  {
    drop: 0.142088787253614,
    time: 8.2,
  },
  {
    drop: 0.140563760859225,
    time: 8.3,
  },
  {
    drop: 0.139073467719819,
    time: 8.4,
  },
  {
    drop: 0.137616712547395,
    time: 8.5,
  },
  {
    drop: 0.136192354743403,
    time: 8.6,
  },
  {
    drop: 0.134799305291322,
    time: 8.7,
  },
  {
    drop: 0.133436523859198,
    time: 8.8,
  },
  {
    drop: 0.132103016095897,
    time: 8.9,
  },
  {
    drop: 1.08605787067282,
    time: 0.9,
  },
  {
    drop: 0.130797831106255,
    time: 9.0,
  },
  {
    drop: 0.129520059091326,
    time: 9.1,
  },
  {
    drop: 0.128268829140921,
    time: 9.2,
  },
  {
    drop: 0.127043307167818,
    time: 9.3,
  },
  {
    drop: 0.125842693972311,
    time: 9.4,
  },
  {
    drop: 0.124666223428149,
    time: 9.5,
  },
  {
    drop: 0.123513160780786,
    time: 9.6,
  },
  {
    drop: 0.122382801049947,
    time: 9.7,
  },
  {
    drop: 0.121274467529231,
    time: 9.8,
  },
  {
    drop: 0.120187510375613,
    time: 9.9,
  },
];
let speedRangeBasedOnSiteSpeed = [];
export function generateRevenue(speed, details) {
  var e = {
    drop: 0,
    time: speed,
  };
  createSpeedRange(speed);
  let selectedRevenue = speedRangeBasedOnSiteSpeed
    .map(getDropRatesForRelevantSpeeds)
    .filter(removeRatesForLessThenHalfASecond)
    .sort(sortSpeedsInDescendingOrder);
  selectedRevenue.unshift(e);
  selectedRevenue = selectedRevenue
    .map(createNewConversionRate, details)
    .map(createConversionDifference, details)
    .map(createAllRevenues, details);
  selectedRevenue = selectedRevenue.find(findRevenueForSelectedSpeed, details);
  void 0 !== selectedRevenue &&
    void 0 !== selectedRevenue &&
    (selectedRevenue = formatInput(
      selectedRevenue.revenue.toFixed(0),
      false,
      false,
    ));
  return Number(selectedRevenue);
}

function createSpeedRange(speed) {
  speedRangeBasedOnSiteSpeed = [];
  for (var e = 0.6; e <= speed; e += 0.1)
    speedRangeBasedOnSiteSpeed.push(parseFloat(e.toFixed(1)));
}

function getDropRatesForRelevantSpeeds(e) {
  return immutableSpeedAndDropRates.find(function (t) {
    return t.time === e;
  });
}

function removeRatesForLessThenHalfASecond(e) {
  return e.time > 0.5;
}

function sortSpeedsInDescendingOrder(e, t) {
  return e.time < t.time ? 1 : e.time > t.time ? -1 : 0;
}

function createNewConversionRate(e, t, n) {
  var i = this.conversion;
  return (
    0 === t && (e.newDrop = 0.01 * i),
    t > 0 && (e.newDrop = n[t - 1].newDrop / (1 - 0.01 * n[t - 1].drop)),
    e
  );
}

function createConversionDifference(e) {
  var t = this.conversion;
  return (e.difference = e.newDrop - 0.01 * t), e;
}

function createAllRevenues(e) {
  var t = this.value,
    n = this.visitors;
  return (e.revenue = e.difference * t * n * 12), e;
}

function findRevenueForSelectedSpeed(e) {
  return 0 === this.selectedSpeed
    ? 0
    : 0 !== this.selectedSpeed
    ? e.time === parseFloat(this.selectedSpeed)
    : void 0;
}

function formatInput(e, t, k) {
  return (
    (e = removeSpecialCharacters(e)),
    (e = makeInputAWholeNumber(e)),
    k && (e = addCommas(e)),
    "currency" === t && (e = addCurrency(e)),
    e
  );
}

function removeSpecialCharacters(e) {
  return "number" == typeof e
    ? e
    : void 0 !== e && null !== e
    ? e.replace(/[\D\s\._\-]+/g, "")
    : void 0;
}

function makeInputAWholeNumber(e) {
  return e ? parseInt(e, 10) : 0;
}
function addCommas(e) {
  return 0 === e ? "" : e.toLocaleString("en-US");
}

function addCurrency(e) {
  return "$" + e;
}
