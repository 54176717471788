<style>
  .error-message {
    color: red;
  }
</style>

<script>
  import { createForm } from "svelte-forms-lib";
  import axios from "axios";
  import { navigate } from "svelte-routing";
  import * as yup from "yup";

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      password: "",
      confirmPassword: "",
      email: "",
    },
    onSubmit: async values => {
      if (values.password !== values.confirmPassword) {
        alert("Passwords do not match");
        return;
      }
      const { email, password } = values;
      try {
        const resp = await axios.post("ENDPOINT" + "auth/register", {
          email,
          password,
          username: email,
        });
        alert(`New user registered with email: ${resp.data.email}`);
      } catch (e) {
        alert(e.response.data.error);
        return;
      }
    },
    validationSchema: yup.object().shape({
      confirmPassword: yup
        .string("Must be a string")
        .required("Confirm Password is required"),
      password: yup.string("Must be a string").required("Password is required"),
      email: yup
        .string("Must be a string")
        .email("Must be a valid email")
        .required("Email is required"),
    }),
  });

  function goToLogin(e) {
    e.preventDefault();
    navigate("/login");
  }
</script>

<svelte:head>
  <title>Peak | Sign Up</title>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover, width=device-width"
  />
  <meta name="theme-color" content="#19222F" />
  <meta name="msapplication-navbutton-color" content="#19222F" />
  <meta name="apple-mobile-web-app-status-bar-style" content="#19222F" />
  <!-- Google Tag Manager -->
  <script>
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TB2K5G6");
  </script>
  <!-- End Google Tag Manager -->
  <style>
    body {
      background-color: #19222f;
    }
  </style>
</svelte:head>

<div
  class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
>
  <div class="max-w-md w-full space-y-8">
    <div>
      <img
        loading="eager"
        class="mx-auto h-12 w-auto"
        src="/assets/images/logo-unit.svg"
        alt="Logo"
      />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign up for a new account
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600"></p>
    </div>
    <form
      on:submit="{handleSubmit}"
      class="mt-8 space-y-6"
      action="#"
      method="POST"
    >
      <input type="hidden" name="remember" value="true" />
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="email-address" class="sr-only">Email address</label>
          <input
            id="email-address"
            name="email"
            type="email"
            on:change="{handleChange}"
            bind:value="{$form.email}"
            autocomplete="email"
            required
            class="appearance-none  rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Email address"
          />
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            on:change="{handleChange}"
            bind:value="{$form.password}"
            autocomplete="current-password"
            required
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Password"
          />
        </div>
        <div>
          <label for="confirmPassword" class="sr-only">Confirm Password</label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            on:change="{handleChange}"
            bind:value="{$form.confirmPassword}"
            autocomplete="current-password"
            required
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Confirm password"
          />
        </div>
      </div>

      {#if $errors}
        {#if $errors.email}
          <span
            class="flex items-center font-medium tracking-wide error-message text-xs mt-1 ml-1"
          >
            {$errors.email}
          </span>
        {/if}
        {#if $errors.password}
          <span
            class="flex items-center font-medium tracking-wide error-message text-xs mt-1 ml-1"
          >
            {$errors.password}
          </span>
        {/if}
        {#if $errors.confirmPassword}
          <span
            class="flex items-center font-medium tracking-wide error-message text-xs mt-1 ml-1"
          >
            {$errors.confirmPassword}
          </span>
        {/if}
      {/if}

      <div class="text-center w-full md:inline-block md:self-start">
        <button
          on:click
          disabled=""
          type="submit"
          tabindex="0"
          class="w-full px-8 py-3 font-bold h-10 focus:outline-none focus:ring-2 focus:ring-brand-red-600 focus:ring-opacity-50 bg-brand-red text-white btn-box-shadow"
        >Sign up</button>
      </div>

      <div class="text-center w-full md:inline-block md:self-start">
        <button
          on:click="{goToLogin}"
          type="button"
          to="/login"
          class="w-full px-5 py- font-bold h-10 focus:outline-none focus:ring-2 focus:ring-black-600 focus:ring-opacity-50 bg-black text-white btn-box-shadow"
        >Sign in to an existing account.</button>
      </div>
    </form>
  </div>
</div>
