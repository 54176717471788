<script>
  import { createForm } from "svelte-forms-lib";
  import * as yup from "yup";
  import { generateRevenue } from "../utils/revenueCalculator";
  import { curves, VALUE_AT_QUANTILE } from "../utils/scoringCalculator";
  import Tooltip from "../components/Tooltip.svelte";

  export let currentPerformanceScore;
  let pageSpeedAtCurrentPerformanceScore, currentRevenue;
  let pageSpeedAtTargetPerformanceScore, targetRevenue;

  const formatNumber = (num, dec = 6) => {
    if (isNaN(num)) {
      return num;
    }

    //
    if (num >= 1.0e15) {
      return (num / 1.0e15).toFixed(dec) + " G";
    } else if (num >= 1.0e12) {
      return (num / 1.0e12).toFixed(dec) + " T";
    } else if (num >= 1.0e9) {
      return (num / 1.0e9).toFixed(dec) + " B";
    } else {
      return num.toLocaleString("en-US");
    }
  };

  const { form, errors, state, handleChange } = createForm({
    initialValues: {
      targetPerformanceScore: Math.min(
        100,
        parseInt(currentPerformanceScore) < 80
          ? 85
          : parseInt(currentPerformanceScore) + 5,
      ),
      monthlyVisitors: "",
      avgTicketSize: "",
      conversionRate: "",
    },
    validationSchema: yup.object().shape({
      targetPerformanceScore: yup
        .number()
        .typeError("Must be a number")
        .max(100, "Maximum allowed value is 100")
        .integer("Must be an integer")
        .nullable()
        .transform(emptyStringToNull),
      monthlyVisitors: yup
        .number()
        .typeError("Must be a number")
        .integer("Must be an integer")
        .nullable()
        .transform(emptyStringToNull),
      avgTicketSize: yup
        .number()
        .typeError("Must be a number")
        .nullable()
        .transform(emptyStringToNull),

      conversionRate: yup
        .number()
        .typeError("Must be a number")
        .max(100, "Maximum allowed value is 100")
        .integer("Must be an integer")
        .nullable()
        .transform(emptyStringToNull),
    }),
  });
  $: recalculateRevenue($errors);
  $: checkForErrors($form);
  // fitty("#fit-text-current-revenue");
  // fitty("#fit-text-target-revenue");
  function shouldRecalculateScore() {
    const anyErrors = Boolean(
      $errors.conversionRate ||
        $errors.avgTicketSize ||
        $errors.targetPerformanceScore ||
        $errors.monthlyVisitors,
    );
    const everyFormValue = Boolean(
      $form.conversionRate &&
        +$form.avgTicketSize &&
        $form.targetPerformanceScore &&
        +$form.monthlyVisitors,
    );
    return Boolean(!anyErrors && everyFormValue);
  }
  function checkForErrors() {
    const maxAllowedValue = 100000000;
    if ($form.conversionRate > 100) {
      $form.conversionRate = Math.floor($form.conversionRate / 10);
    }
    if ($form.targetPerformanceScore > 100) {
      $form.targetPerformanceScore = Math.floor(
        $form.targetPerformanceScore / 10,
      );
    }
    if ($form.avgTicketSize > maxAllowedValue) {
      $form.avgTicketSize = Math.floor($form.avgTicketSize / 10);
    }
    if ($form.monthlyVisitors > maxAllowedValue) {
      $form.monthlyVisitors = Math.floor($form.monthlyVisitors / 10);
    }
  }
  function recalculateRevenue() {
    if (shouldRecalculateScore()) {
      //dividing by 1000 to convert milliseconds to seconds
      pageSpeedAtCurrentPerformanceScore =
        VALUE_AT_QUANTILE(curves.v6.mobile.SI, currentPerformanceScore / 100) /
        1000;
      pageSpeedAtCurrentPerformanceScore = Math.min(
        Math.max(Math.round(pageSpeedAtCurrentPerformanceScore * 10) / 10, 1),
        12,
      );

      pageSpeedAtTargetPerformanceScore =
        VALUE_AT_QUANTILE(
          curves.v6.mobile.SI,
          $form.targetPerformanceScore / 100,
        ) / 1000;
      pageSpeedAtTargetPerformanceScore = Math.min(
        Math.max(Math.round(pageSpeedAtTargetPerformanceScore * 10) / 10, 1),
        12,
      );
      const details = {
        selectedSpeed: pageSpeedAtTargetPerformanceScore,
        conversion: parseInt($form.conversionRate),
        value: parseInt($form.avgTicketSize),
        visitors: parseInt($form.monthlyVisitors),
      };
      currentRevenue =
        (details.conversion * details.value * 12 * details.visitors) / 100;
      const increasedRevenue = generateRevenue(
        pageSpeedAtCurrentPerformanceScore,
        details,
      );
      targetRevenue =
        currentRevenue + (isNaN(increasedRevenue) ? 0 : increasedRevenue);
    } else {
      currentRevenue = "";
      targetRevenue = "";
    }
  }
  function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === "string" && originalValue === "") {
      return null;
    }
    return parseInt(value);
  }
</script>

<div class="flex flex-col md:flex-row mt-3px">
  <form class="space-y-6 md:w-7/12 md:mr-4">
    <div>
      <label
        class="tracking-wider text-gray-700 uppercase text-ui-label"
      >Current performance score
        <Tooltip
          message="Your Target Performance Score must always be higher than your Current Performance Score."
          width="210px"
          height="96px"
        />
        <input
          type="text"
          value="{currentPerformanceScore}"
          disabled
          name="currentPerformanceScore"
          id="currentPerformanceScore"
          placeholder="85"
          class="w-full py-2 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
        />
      </label>
    </div>

    <div>
      <label class="tracking-wider text-gray-700 uppercase text-ui-label">Target
        performance score
        <Tooltip
          message="Performance score you want to achive"
          width="210px"
          height="64px"
        />
        <input
          type="number"
          min="0"
          bind:value="{$form.targetPerformanceScore}"
          on:input="{handleChange}"
          on:blur="{e => {
            if (+$form.targetPerformanceScore < +currentPerformanceScore) {
              $form.targetPerformanceScore = currentPerformanceScore;
            }
          }}"
          name="targetPerformanceScore"
          id="targetPerformanceScore"
          placeholder="90"
          autocomplete="off"
          class="w-full py-2 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
        />
      </label>
    </div>
    <div>
      <label class="tracking-wider text-gray-700 uppercase text-ui-label">Avg.
        Monthly Visitors
        <Tooltip message="Monthly active users" width="210px" height="48px" />
        <input
          type="number"
          min="0"
          bind:value="{$form.monthlyVisitors}"
          on:input="{handleChange}"
          name="monthlyVisitors"
          id="monthlyVisitors"
          placeholder="250"
          class="w-full py-2 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
        />
      </label>
    </div>
    <div>
      <label class="tracking-wider text-gray-700 uppercase text-ui-label">
        Avg.Ticket Size
        <Tooltip message="Cost of Ticket" width="210px" height="48px" />
        <input
          type="number"
          min="0"
          bind:value="{$form.avgTicketSize}"
          on:input="{handleChange}"
          name="avgTicketSize"
          id="avgTicketSize"
          placeholder="20$"
          class="w-full py-2 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
        />
      </label>
    </div>
    <div>
      <label
        class="tracking-wider text-gray-700 uppercase text-ui-label "
      >Conversion Rate
        <Tooltip message="User Conversion Rate" width="210px" height="48px" />
        <input
          type="number"
          min="0"
          max="100"
          bind:value="{$form.conversionRate}"
          on:input="{handleChange}"
          name="conversionRate"
          id="conversionRate"
          placeholder="15%"
          class="w-full py-2 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
        />
      </label>
    </div>
  </form>
  <div
    class="p-6 mt-4 md:mt-0 space-y-4 text-gray-700 bg-brand-gray text-body-small md:flex-1 md:self-start"
  >
    <p class="tracking-wide uppercase">Current Revenue</p>
    {#if currentRevenue}
      <h3
        class="tracking-tight text-white"
        id="fit-text-current-revenue"
        title="{currentRevenue.toLocaleString('en-US')}"
      >
        {currentRevenue ? '$ ' : ''}
        {currentRevenue ? formatNumber(currentRevenue) : ''}
      </h3>
    {:else}
      <h3 class="tracking-wide">...</h3>
    {/if}

    <p class="tracking-wide uppercase">Target Revenue</p>
    {#if targetRevenue}
      <h3
        class="tracking-tight text-white"
        id="fit-text-target-revenue"
        title="{targetRevenue.toLocaleString('en-US')}"
      >
        {targetRevenue ? '$ ' : ''}
        {targetRevenue ? formatNumber(targetRevenue) : ''}
      </h3>
    {:else}
      <h3 class="tracking-wide">...</h3>
    {/if}
    <p
      class="tracking-wide text-white text-body-regular"
      style="line-height:180%;"
    >
      You can gain
      <strong>
        {targetRevenue && currentRevenue ? '$' : '...'}{targetRevenue && currentRevenue ? formatNumber(targetRevenue - currentRevenue) : ''}
      </strong>
      <br />
      in revenue every year by improving perfomance.
    </p>
  </div>
</div>
