<script>
  import { Router, Link, Route } from "svelte-routing";
  import GlobalStyle from "./components/GlobalStyle.svelte";
  import Compare from "./pages/Compare.svelte";
  import Home from "./pages/Home.svelte";
  import Report from "./pages/Report.svelte";
  // import Login from "./pages/Login.svelte";
  import SignUp from "./pages/SignUp.svelte";
</script>

<GlobalStyle />
<Router>
  <Route path="/" component="{Home}" />
  <Route path="/report" component="{Report}" />
  <Route path="/compare" component="{Compare}" />
  <!-- <Route path="/login" component="{Login}" /> -->
  <Route path="/register" component="{SignUp}" />
</Router>
