<script>
  export let performanceMap, url, backgroundClass;
</script>

<div class="flex justify-between text-xs px-1 items-baseline">
  <span
    class="uppercase tracking-wider"
  >{url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}
  </span>
  <span
    class="text-label-medium text-black {performanceMap.performanceScoreFont}"
  >{performanceMap.performanceScore}</span>
</div>
<div class="bg-gray-100 h-4 relative">
  <span
    style="width:{performanceMap.performanceScore}%;opacity:0;"
    class="block h-full absolute {backgroundClass}"
  ></span>
  <span
    style="width:{performanceMap.performanceScore}%;"
    class="block {performanceMap.performanceClass} h-full absolute"
  ></span>
  <span style="left:85%;" class="h-4 w-4 absolute">
    <img src="/assets/images/benchmark.svg" alt="benchmark" />
  </span>
</div>
