<style>
  .aside-display-text {
    font-size: 20px;
    line-height: 140%;
    font-weight: 800;
  }
  .aside-display {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  }
  .aside-container {
    max-width: 300px;
  }
  .sending-mail {
    animation: smooth-scale 5s infinite;
  }

  .full-page {
    position: fixed;
    display: block;
    top: 0px;
    height: 100%;
    width: 100vw;
    left: 0px;
    z-index: 40;
  }
  @keyframes smooth-scale {
    from {
      width: 1%;
    }
    to {
      width: 100%;
    }
  }
  .compare-head {
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    top: 0px;
  }
  @media only screen and (max-width: 768px) {
    .compare-head {
      background: linear-gradient(180deg, #f5f8fa 0%, #ffffff 100%);
      top: 100px;
      width: 100vw;
      margin-left: calc((1rem + 1px) * -1);
    }
  }

  .min-w-290 {
    min-width: 290px;
  }
</style>

<script>
  import axios from "axios";
  import { query } from "@intrnl/svelte-query";
  import { fly } from "svelte/transition";
  import { navigate } from "svelte-routing";
  import scrollIntoView from "scroll-into-view-if-needed";
  import queryString from "query-string";
  import isEmailValid from "../utils/isEmailValid";
  import {
    getSiteData,
    setCompData,
    setSiteData,
    getCompSiteData,
    trimData,
  } from "../utils/readStorage";
  import clickOutside from "../utils/clickOutside";
  import { performanceData } from "../utils/performanceData";
  import Modal from "../components/Modal.svelte";
  import {
    QUANTILE_AT_VALUE,
    curves,
    industryAverages,
  } from "../utils/scoringCalculator";
  import currentElementPosition from "../utils/currentElementPosition";
  import {
    protocolLessDomain,
    protocolLessUrl,
    appendProtocol,
  } from "../utils/urlFilterMethods";
  import Header from "../components/Header.svelte";
  import PerformanceScoreChart from "../components/PerformanceScoreChart.svelte";
  import PageSpeedChart from "../components/PageSpeedChart.svelte";
  import Loader from "../components/Loader.svelte";
  import Footer from "../components/Footer.svelte";
  import IndustrySelect from "../components/IndustrySelect.svelte";
  import Tooltip from "../components/Tooltip.svelte";

  export let location;

  const { url, category, competitorUrl } = queryString.parse(location.search);
  let initialUrl = url;
  let initialCompetitorUrl = competitorUrl;
  let selectedCompareIndustryIndex = category;
  let isCompareSelectOpen = false;
  let isCompareButtonEnabled = false;
  $: isChanged = url != initialUrl || competitorUrl != initialCompetitorUrl;
  let formFullScreen = false;
  function handleFormClick() {
    if (window.innerWidth >= 450) return;
    formFullScreen = true;
  }

  function handleKeyDown(e, modalType, isSubmitEnabled) {
    if (e.keyCode === 13 && isSubmitEnabled) {
      if (modalType === "compare") {
        onCompareSubmit();
      } else if (modalType === "report") {
        sendReportToEmail();
      }
    }
  }

  function checkCompareFormValidity(url, industry, competitorUrl) {
    let exp = new RegExp(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    const areBothLinksDifferent =
      protocolLessUrl(url) !== protocolLessUrl(competitorUrl);
    isCompareButtonEnabled =
      url.match(exp) &&
      (industry || industry == 0) &&
      competitorUrl.match(exp) &&
      areBothLinksDifferent &&
      isChanged;
  }

  $: checkCompareFormValidity(
    initialUrl,
    selectedCompareIndustryIndex,
    initialCompetitorUrl,
  );

  const sendReportToEmail = async () => {
    if (reportEmail && isEmailValid(reportEmail)) {
      // isDownloadModalVisible = true;
      isArrowOpaque = true;
      mailSending = true;
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
      };

      let endPoint = "ENDPOINT" + "report/compare/";
      let resp = await axios.post(
        endPoint,
        {
          email: reportEmail,
          url,
          competitorUrl,
          category,
          data: trimData(localStorage.getItem("cached-data")),
          competitorData: trimData(localStorage.getItem("cached-comp-data")),
        },
        headers,
      );
      if (resp.status === 200) {
        mailSent = true;
        mailSending = false;
      }
    }
  };
  let reportEmail = "";
  let mailSent = false;
  let mailSending = false;

  let isDownloadModalVisible = false;
  let isArrowOpaque = false;
  const openDownloadReportModal = () => (isDownloadModalVisible = true);

  $: isEmailValid(reportEmail)
    ? (isArrowOpaque = false)
    : (isArrowOpaque = true);
  const onCompareSubmit = () => {
    window.location.href = `compare?url=${initialUrl}&category=${selectedCompareIndustryIndex}&competitorUrl=${initialCompetitorUrl}`;
  };

  function backToReportPage() {
    navigate(`report?url=${url}&category=${category}`);
  }
  let showMetrics = false;
  const performanceKeys = [
    "first-contentful-paint",
    "speed-index",
    "largest-contentful-paint",
    "interactive",
    "total-blocking-time",
    "cumulative-layout-shift",
  ];
  let performanceMap = {
    performanceKeyScore: [],
    performanceOpportunities: [],
    performanceScore: "",
    performanceKeyword: "",
    performanceReviewUnderlineClass: "",
    performanceClass: "",
    pageSpeedClass: "",
    pageSpeedIndexPercentage: "",
    performanceImageFile: "",
    performanceWeightClass: "",
  };
  let competitorPerformanceMap = { ...performanceMap };
  async function fetchRepoData(key, queryUrl, usecache = 1) {
    let urlTobeSent = queryUrl;
    let cached;

    if (!/^https?:\/\//i.test(queryUrl)) {
      urlTobeSent = "http://" + queryUrl;
    }

    if (!!+usecache && key === "repoData") {
      cached = getSiteData(urlTobeSent, category);
      if (cached) return cached;
    }

    if (!!+usecache && key === "competitorData") {
      cached = getCompSiteData(urlTobeSent, category);
      if (cached) return cached;
    }

    let resp = await axios.get(
      "https://content-pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed",
      {
        params: {
          url: urlTobeSent,
          strategry: "MOBILE",
          category: "PERFORMANCE",
          key: "PSI_API_KEY",
        },
      },
    );
    if (!resp.status === 200) throw new Error(`HT TP error: ${resp.status}`);
    if (key === "repoData") {
      setSiteData(urlTobeSent, category, resp.data);
    } else if (key === "competitorData") {
      setCompData(urlTobeSent, category, resp.data);
    }
    return resp.data;
  }

  let q = query(["repoData", url], fetchRepoData, {
    refetchOnWindowFocus: false,
  });
  let competitorQuery = query(
    ["competitorData", competitorUrl],
    fetchRepoData,
    {
      refetchOnWindowFocus: false,
    },
  );

  $: ({ status, error, data } = $q);
  $: ({
    status: competitorStatus,
    error: competitorError,
    data: competitorData,
  } = $competitorQuery);
  $: if (data && data.lighthouseResult) {
    calculatePerformanceAspects("own", $q);
  }
  $: if (competitorData && competitorData.lighthouseResult) {
    calculatePerformanceAspects("opponent", $competitorQuery);
  }

  $: replaceHistoryUrl(data, competitorData);

  $: calculatePerformanceWeightClass(performanceMap, competitorPerformanceMap);

  const pageSpeedIndustryAveragePercentage = Math.round(
    (QUANTILE_AT_VALUE(
      curves.v6.mobile.SI,
      industryAverages[category].average,
    ) +
      Number.EPSILON) *
      100,
  );
  let pageSpeedIndustryAverageClass = "";
  if (pageSpeedIndustryAveragePercentage < 40) {
    pageSpeedIndustryAverageClass = "poor-gradient";
  } else if (pageSpeedIndustryAveragePercentage < 85) {
    pageSpeedIndustryAverageClass = "average-gradient";
  } else {
    pageSpeedIndustryAverageClass = "good-gradient";
  }
  let scrollY;
  $: {
    currentElementPosition(
      document.getElementsByClassName("scroll-detector")[0],
      document.getElementsByClassName("inverse-color-input")[0],
      scrollY,
    );
  }
  function replaceHistoryUrl(initialData, initialCompetitorData) {
    if (
      initialData &&
      initialData.lighthouseResult &&
      initialCompetitorData &&
      initialCompetitorData.lighthouseResult
    ) {
      initialUrl = initialData.lighthouseResult.finalUrl;
      initialCompetitorUrl = initialCompetitorData.lighthouseResult.finalUrl;
      history.replaceState(
        {},
        null,
        `compare?url=${initialUrl}&category=${category}&competitorUrl=${initialCompetitorUrl}`,
      );
    }
  }
  function calculatePerformanceAspects(type, queryStore) {
    let performanceMapObj = {
      performanceKeyScore: [],
      performanceOpportunities: [],
      performanceScore: "",
      performanceKeyword: "",
      performanceReviewUnderlineClass: "",
      performanceClass: "",
      pageSpeedClass: "",
      pageSpeedIndexPercentage: "",
      performanceImageFile: "",
      performanceWeightClass: "",
    };
    const categories = queryStore.data.lighthouseResult.categories;
    const audits = queryStore.data.lighthouseResult.audits;
    performanceKeys.forEach(key => {
      const { description, displayValue, title, numericValue, id } = audits[
        key
      ];
      let formattedDisplayValue = displayValue;
      if (key === "total-blocking-time") {
        formattedDisplayValue = Math.round((numericValue / 1000) * 10) / 10;
        formattedDisplayValue = formattedDisplayValue.toString() + " s";
      }
      if (key === "cumulative-layout-shift") {
        formattedDisplayValue = Math.round(numericValue * 100) / 100;

        if (!(formattedDisplayValue.toString().length >= 3)) {
          formattedDisplayValue = formattedDisplayValue
            .toString()
            .padEnd(3, "0");
        }
      }
      performanceMapObj.performanceKeyScore = [
        ...performanceMapObj.performanceKeyScore,
        {
          description: description.split(".")[0] + ".",
          displayValue: formattedDisplayValue,
          title,
          numericValue,
          key: id,
        },
      ];
    });

    const pageSpeedIndexAuditScore =
      performanceMapObj.performanceKeyScore[1].numericValue;

    //rounding off 0.34343 to two decimal places for percentage
    performanceMapObj.pageSpeedIndexPercentage = Math.round(
      (QUANTILE_AT_VALUE(curves.v6.mobile.SI, pageSpeedIndexAuditScore) +
        Number.EPSILON) *
        100,
    );

    for (let audit in audits) {
      if (
        audits[audit].details &&
        audits[audit].details.type === "opportunity" &&
        audits[audit].score < 1
      ) {
        performanceMapObj.performanceOpportunities = [
          ...performanceMapObj.performanceOpportunities,
          audits[audit],
        ];
      }
    }
    performanceMapObj.performanceScore = (
      categories.performance.score * 100
    ).toFixed(0);

    if (performanceMapObj.performanceScore < 40) {
      performanceMapObj.performanceKeyword = "is poor";
      performanceMapObj.performanceReviewUnderlineClass = "bad";
      performanceMapObj.performanceClass = "poor-gradient";
      performanceMapObj.performanceImageFile = "badge-bad";
    } else if (performanceMapObj.performanceScore < 85) {
      performanceMapObj.performanceKeyword = "needs improvement";
      performanceMapObj.performanceReviewUnderlineClass = "needs improvement";
      performanceMapObj.performanceClass = "average-gradient";
      performanceMapObj.performanceImageFile = "badge-ok";
    } else {
      performanceMapObj.performanceKeyword = "is good";
      performanceMapObj.performanceReviewUnderlineClass = "good";
      performanceMapObj.performanceClass = "good-gradient";
      performanceMapObj.performanceImageFile = "badge-good";
    }
    if (performanceMapObj.pageSpeedIndexPercentage < 40) {
      performanceMapObj.pageSpeedClass = "poor-gradient";
    } else if (performanceMapObj.pageSpeedIndexPercentage < 85) {
      performanceMapObj.pageSpeedClass = "average-gradient";
    } else {
      performanceMapObj.pageSpeedClass = "good-gradient";
    }
    if (type === "own") {
      performanceMap = { ...performanceMapObj };
    } else if (type === "opponent") {
      competitorPerformanceMap = { ...performanceMapObj };
    }
  }
  function calculatePerformanceWeightClass() {
    {
      if (
        performanceMap.performanceScore &&
        competitorPerformanceMap.performanceScore
      ) {
        const myPerformanceScore = parseInt(performanceMap.performanceScore);
        const oppositionScore = parseInt(
          competitorPerformanceMap.performanceScore,
        );
        if (myPerformanceScore > oppositionScore) {
          performanceMap.performanceScoreFont = "font-extrabold";
          competitorPerformanceMap.performanceScoreFont = "";
          performanceMap.performanceWeightClass = "mt-4";
          competitorPerformanceMap.performanceWeightClass = "md:mt-12";
        } else if (myPerformanceScore < oppositionScore) {
          performanceMap.performanceScoreFont = "";
          competitorPerformanceMap.performanceScoreFont = "font-extrabold";
          performanceMap.performanceWeightClass = "md:mt-12";
          competitorPerformanceMap.performanceWeightClass = "mt-4";
        } else {
          performanceMap.performanceScoreFont = "font-extrabold";
          competitorPerformanceMap.performanceScoreFont = "font-extrabold";
          performanceMap.performanceWeightClass = "mt-4";
          competitorPerformanceMap.performanceWeightClass = "mt-4";
        }
      }
    }
  }

  const highlightURL = selector => {
    let mobileurl = document.querySelector(selector);
    if (mobileurl) {
      mobileurl.focus();
      mobileurl.setSelectionRange(0, mobileurl.value.length);
    }
  };

  function isValidURL(url) {
    return url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
  }

  const closeReportModal = () => {
    isDownloadModalVisible = false;
    isArrowOpaque = true;
    mailSent = false;
    if (!mailSending) {
      reportEmail = "";
    }
  };

  const formatNumber = (value, ignoreString = true, decimal = 2) => {
    return isNaN(value) && ignoreString
      ? value
      : parseFloat(value).toFixed(decimal);
  };

  const getClassName = (val1, key) => {
    let value = formatNumber(val1, false, 2);
    let performanceParam = performanceData[key];
    for (var prop in performanceParam) {
      if (
        value >= performanceParam[prop][0] &&
        value <= performanceParam[prop][1]
      ) {
        return prop;
      }
    }
    return "poor";
  };

  const toggleDescription = key => {
    console.log("toggleDescription called with ", key);
    let ele = document.querySelector('p[data-link="' + key + '"]');
    ele.classList.toggle("xs:hidden");
  };
</script>

<svelte:head>
  <title>Compare</title>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover, width=device-width"
  />
  <meta name="theme-color" content="#19222F" />
  <meta name="msapplication-navbutton-color" content="#19222F" />
  <meta name="apple-mobile-web-app-status-bar-style" content="#19222F" />
  <!-- Google Tag Manager -->
  <script>
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TB2K5G6");
  </script>
  <!-- End Google Tag Manager -->
</svelte:head>

<svelte:window bind:scrollY />

{#if data && competitorData}
  <header class="fixed z-30 w-full text-white bg-gray-1000 md:hidden">
    <div class="w-11/12 max-w-screen-lg pt-2 pb-4 mx-auto sm:pb-6 xl:w-10/12 ">
      <div class="flex items-center ml-2 space-x-4">
        <i on:click="{backToReportPage}" class="material-icons"> arrow_back </i>
        <span class="tracking-wide text-gray-100">Comparing Performance</span>
      </div>
      <div class="flex items-center space-x-3">
        <div class="relative flex-1 mt-3 rounded-md shadow-sm ">
          <input
            style="background-color:rgba(255,255,255,0.1)"
            value="{initialUrl + ' v. ' + competitorUrl}"
            class="block w-full pt-2 pb-2 pl-2 pr-10 text-gray-100 outline-none form-input sm:text-sm sm:leading-5 placeholder-gray-390"
          />
          <div
            on:click="{() => (formFullScreen = true)}"
            class="absolute inset-y-0 right-0 flex pr-3 mt-3 pointer-events-auto"
          >
            <i class="material-icons text-base">mode_edit</i>
          </div>
        </div>
        <div
          class="relative"
          on:click="{() => {
            window.location.reload();
          }}"
        >
          <i class="mt-4 material-icons pointer-events-none">refresh</i>
        </div>
      </div>
    </div>
  </header>
  <main class="text-white bg-brand-gray">
    <div class="w-11/12 max-w-screen-lg md:pt-6 pt-4 pb-4 mx-auto xl:w-10/12 ">
      <Header hideMobileHeader />
    </div>
    <div>
      <div class="mt-4">
        <div
          class="w-11/12 max-w-screen-lg pt-8 pb-4 mx-auto md:pt-4 md:pb-0 sm:pb-6 xl:w-10/12"
        >
          <div class="space-y-6">
            <section
              class="flex flex-col items-center md:flex-row md:justify-evenly md:items-start md:mt-12"
            >
              <div
                class=" md:flex flex-col md:justify-between {performanceMap.performanceWeightClass}"
                style="flex-basis:33%;max-width:260px"
              >
                <div
                  class="relative flex flex-col items-center px-6 py-6 text-center bg-center bg-no-repeat "
                  style="background-image:url('/assets/images/{performanceMap.performanceImageFile}.svg')"
                >
                  <p
                    class="tracking-tight text-extra-jumbo"
                    style="letter-spacing:0em;"
                  >
                    {performanceMap.performanceScore}
                  </p>
                  <div
                    class="absolute"
                    style="top:50px;right:calc(50% - 130px);z-index:35;"
                  >
                    <Tooltip
                      message="Your performance score will fluctuate slightly each time you run the test. This is normal, and could be caused by changes in a number of underlying conditions, such as local network availability, internet traffic routing changes, etc."
                      width="300px"
                      height="170px"
                      direction="top-right"
                      text="text-body-small"
                    />
                  </div>
                  <div class="flex items-center -mt-4">
                    <div
                      class="w-4 h-1 {performanceMap.performanceClass}"
                    ></div>
                    <p class="ml-2 text-base leading-relaxed">
                      {performanceMap.performanceReviewUnderlineClass
                        .charAt(0)
                        .toUpperCase() + performanceMap.performanceReviewUnderlineClass.slice(1)}
                    </p>
                  </div>
                </div>
                <p class="mt-0 text-center text-body-large md:mt-0">
                  {url
                    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
                    .split('/')[0]}
                </p>
                <div
                  class="hidden p-1 mt-4 bg-white rounded-phone h-phone md:block "
                  style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
                >
                  <img
                    class="object-contain object-top w-full h-full rounded-phone"
                    src="https://image.thum.io/get/auth/15030-489357e7d4ffa46eb30fa08f26ed67c4/get/width/502/viewportWidth/502/crop/944/{appendProtocol(url)}"
                    alt="Screenshot of {url}"
                  />
                </div>
              </div>
              <p
                class="text-center text-gray-700 opacity-50 text-extra-jumbo md:mt-8"
              >
                vs
              </p>
              <div
                class=" md:flex flex-col md:justify-between {competitorPerformanceMap.performanceWeightClass}"
                style="flex-basis:33%;max-width:260px"
              >
                <div
                  class="relative flex flex-col items-center px-6 py-6 text-center bg-center bg-no-repeat "
                  style="background-image:url('/assets/images/{competitorPerformanceMap.performanceImageFile}.svg')"
                >
                  <p
                    class="tracking-tight text-extra-jumbo"
                    style="letter-spacing:0em;"
                  >
                    {competitorPerformanceMap.performanceScore}
                  </p>
                  <div
                    class="absolute"
                    style="top:50px;right:calc(50% - 130px);z-index:35;"
                  >
                    <Tooltip
                      message="Your performance score will fluctuate slightly each time you run the test. This is normal, and could be caused by changes in a number of underlying conditions, such as local network availability, internet traffic routing changes, etc."
                      width="300px"
                      height="170px"
                      direction="top-right"
                      text="text-body-small"
                    />
                  </div>
                  <div class="flex items-center -mt-4">
                    <div
                      class="w-4 h-1 {competitorPerformanceMap.performanceClass}"
                    ></div>
                    <p class="ml-2 text-base leading-relaxed">
                      {competitorPerformanceMap.performanceReviewUnderlineClass
                        .charAt(0)
                        .toUpperCase() + competitorPerformanceMap.performanceReviewUnderlineClass.slice(1)}
                    </p>
                  </div>
                </div>
                <p class="mt-0 text-center text-body-large md:mt-0">
                  {competitorUrl
                    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
                    .split('/')[0]}
                </p>
                <div
                  class="hidden p-1 mt-4 bg-white h-phone rounded-phone md:block"
                  style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
                >
                  <img
                    class="object-contain object-top w-full h-full rounded-phone"
                    src="https://image.thum.io/get/auth/15030-489357e7d4ffa46eb30fa08f26ed67c4/get/width/502/viewportWidth/502/crop/944/{appendProtocol(competitorUrl)}"
                    alt="Screenshot of {competitorUrl}"
                  />
                </div>
              </div>
            </section>
            <div
              class="flex flex-col space-y-6 md:flex-row md:space-y-0 md:w-11/12 md:hidden"
            >
              <button
                on:click="{openDownloadReportModal}"
                class="w-full px-2 py-4 font-extrabold tracking-wider border-2 shadow-md md:mr-3 md:w-2/6 bg-brand-red text-label-small border-brand-red"
              >Download the full report</button>
              <button
                disabled="{!isCompareButtonEnabled}"
                class="{isCompareButtonEnabled ? 'text-white' : 'text-gray-500'}  {isCompareButtonEnabled ? 'bg-brand-red' : 'bg-gray-100'} w-full py-4 font-extrabold tracking-wider border-2 border-gray-300 md:w-2/6 bg-brand-gray text-label-small"
              >Compare</button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white scroll-detector md:-mt-20 md:relative">
        <div
          class="w-11/12 max-w-screen-lg pt-6 pb-4 mx-auto border-b-2 border-white xl:w-12/13 sm:pb-6 text-brand-gray md:flex md:justify-between"
        >
          <aside
            on:keydown="{e => handleKeyDown(e, 'compare', isCompareButtonEnabled)}"
            style="top:{formFullScreen ? '0' : '32px'}"
            class="sticky self-start md:block md:w-1/4 text-brand-gray md:mt-12 transition-all duration-300 ease-out {formFullScreen ? 'full-page' : 'hidden aside-container'}"
          >
            {#if formFullScreen}
              <div class="absolute -mr-2" style="top:0;right:10px;z-index:1;">
                <button
                  on:click="{() => (formFullScreen = false)}"
                  type="button"
                  class="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-brand-gray hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-100"
                  aria-label="Close menu"
                >
                  <svg
                    class="w-6 h-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            {/if}
            <div
              class="relative px-5 pb-5 space-y-4 bg-white aside-display pt-7 {formFullScreen ? 'h-full' : ''}"
            >
              {#if !formFullScreen}
                <h4 class="aside-display-text">Comparing performance</h4>
              {/if}
              <div class="relative">
                <label
                  class="tracking-wider text-gray-700 uppercase text-body-small"
                >url
                  <input
                    id="current-url"
                    type="text"
                    bind:value="{initialUrl}"
                    placeholder="https://twitch.tv"
                    class="w-full py-2 pr-6 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
                  />
                  <div
                    class="absolute bottom-0 right-0 flex mb-2 pointer-events-none"
                    on:click="{() => {
                      if (isValidURL(initialUrl)) {
                        let param = queryString.parse(window.location.search);
                        param.url = initialUrl;
                        param.usecache = 0;
                        window.location.search = window.decodeURIComponent(queryString.stringify(param));
                      } else {
                        highlightURL('#current-url');
                      }
                    }}"
                  >
                    <i
                      class="cursor-pointer pointer-events-auto material-icons"
                    >arrow_forward</i>
                  </div>
                </label>
              </div>

              <div>
                <p
                  class="tracking-wider text-gray-700 uppercase text-body-small"
                >
                  Industry
                </p>
                <IndustrySelect
                  bind:selectedIndustryIndex="{selectedCompareIndustryIndex}"
                  bind:isSelectOpen="{isCompareSelectOpen}"
                />
              </div>
              <div class="relative">
                <label
                  class="tracking-wider text-gray-700 uppercase text-body-small"
                >competitor url
                  <input
                    id="current-comp-url"
                    type="text"
                    bind:value="{initialCompetitorUrl}"
                    placeholder="https://mixer.com"
                    class="w-full py-2 pr-6 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
                  />
                  <div
                    class="absolute bottom-0 right-0 flex mb-2 pointer-events-none"
                    on:click="{() => {
                      if (isValidURL(initialCompetitorUrl)) {
                        let param = queryString.parse(window.location.search);
                        param.competitorUrl = initialCompetitorUrl;
                        param.usecache = 0;
                        window.location.search = window.decodeURIComponent(queryString.stringify(param));
                      } else {
                        highlightURL('#current-comp-url');
                      }
                    }}"
                  >
                    <i
                      class="cursor-pointer pointer-events-auto material-icons"
                    >arrow_forward</i>
                  </div>
                </label>
              </div>
              <button
                on:click="{onCompareSubmit}"
                disabled="{!isCompareButtonEnabled}"
                class="{isCompareButtonEnabled ? 'text-white' : 'text-gray-500'}  {isCompareButtonEnabled ? 'bg-brand-red' : 'bg-gray-100'} w-full px-5 py-2 font-extrabold tracking-wider text-white outline-none bg-brand-red text-label-medium {formFullScreen ? ' absolute ' : ''}"
                style="{formFullScreen ? 'bottom: 1rem; left: 1rem; width: calc(100vw - 2rem)' : ''}"
              >Compare
              </button>
            </div>
            <button
              on:click="{openDownloadReportModal}"
              class="w-full px-4 py-4 mt-4 font-extrabold tracking-wider text-white aside-display text-label-small bg-brand-red"
            >Download the full report</button>
            <button
              on:click="{backToReportPage}"
              class="w-full px-4 py-4 mt-4 font-extrabold tracking-wider text-gray-500 truncate bg-white border-2 border-gray-300 text-label-small"
            >Back to the report for
              {protocolLessDomain(url)}
            </button>
          </aside>
          <div class="mt-8 md:w-3/5 md:mt-12">
            <section class="space-y-4 ">
              <h3 id="performance-score-title">Performance Score</h3>
              <div class="relative">
                <PerformanceScoreChart
                  performanceMap="{performanceMap}"
                  url="{url}"
                  backgroundClass="bg-brand-gray"
                />
                <PerformanceScoreChart
                  performanceMap="{competitorPerformanceMap}"
                  url="{competitorUrl}"
                  backgroundClass="bg-gray-700"
                />
              </div>
              <div class="flex justify-between space-x-2 text-xs">
                <div class="flex space-x-1">
                  <span class="w-4 h-1 mt-2 bg-error"></span>
                  <div>
                    <span class="text-brand-gray">Poor</span>
                    <span
                      class="text-gray-700 whitespace-normal sm:whitespace-pre-line"
                    >0-39</span>
                  </div>
                </div>
                <div class="flex space-x-1 ">
                  <span class="w-4 h-1 mt-2 bg-warning"></span>
                  <div>
                    <span class="text-brand-gray">Needs improvement</span>
                    <span
                      class="text-gray-700 whitespace-normal sm:whitespace-pre-line"
                    >40-85</span>
                  </div>
                </div>
                <div class="flex space-x-1">
                  <span class="w-4 h-1 mt-2 bg-success "></span>
                  <div>
                    <span class="text-brand-gray">Good</span>
                    <span
                      class="text-gray-700 whitespace-normal sm:whitespace-pre-line"
                    >85-100</span>
                  </div>
                </div>
                <div class="flex space-x-1">
                  <span class="w-4 h-4 mr-5px">
                    <img src="/assets/images/benchmark.svg" alt="benchmark" />
                  </span>
                  <div>
                    <span class="text-brand-gray">Benchmark</span>
                    <span
                      class="text-gray-700 whitespace-normal sm:whitespace-pre-line"
                    >85</span>
                  </div>
                </div>
              </div>
              <div>
                <p class="leading-relaxed tracking-wide">
                  This score is calculated based on 6 metrics that impact the
                  performance of your site.
                </p>
                {#if showMetrics}
                  <section class="mt-8 space-y-10">
                    <article
                      class="sticky top-0 md:top-0 xs:top-100-px flex items-start md:items-start xs:items-center h-8 justify-end md:justify-end xs:justify-around z-10 compare-head"
                      style=""
                    >
                      <div
                        class="text-gray-700 truncate md:text-gray-700 xs:text-black"
                        style="max-width:5.5rem;"
                      >
                        {initialUrl
                          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
                          .split('/')[0]}
                      </div>
                      <div
                        class="font-extrabold body-text-small hidden md:hidden sm:block xs:block"
                      >
                        VS
                      </div>
                      <div
                        class="md:ml-10 text-gray-700 truncate  md:text-gray-700 xs:text-black"
                        style="max-width:5.5rem"
                      >
                        {competitorUrl
                          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
                          .split('/')[0]}
                      </div>
                    </article>
                    {#each performanceMap.performanceKeyScore as { description, title, displayValue, key }, i}
                      <article
                        class="flex items-start justify-between flex-row md:flex-row sm:flex-col xs:flex-col"
                      >
                        <div class="w-auto md:w-auto xs:w-full">
                          <p class="font-extrabold body-text-small mb-3">
                            {title}
                            <span
                              class="material-icons float-right w-4 h-4 mr-2 text-gray-700 bg-gray-100 hidden md:hidden xs:block"
                              on:click="{e => {
                                toggleDescription(key);
                              }}"
                            >info_outlined</span>
                          </p>
                          <p
                            class="mb-5 tracking-wide max-w-sm text-gray-700 block md:block xs:hidden"
                            data-link="{key}"
                          >
                            {description}
                          </p>
                        </div>
                        <div class="flex w-auto md:w-auto sm:w-full xs:w-full">
                          <div
                            class="relative flex items-center justify-center flex-grow-0 md:flex-grow-0 sm:flex-grow xs:flex-grow flex-shrink-0 w-9 md:w-9 sm:w-auto xs:w-auto h-9 p-3 font-extrabold text-brand-gray whitespace-no-wrap text-body-small bg-gray-100"
                            title="{initialUrl}"
                          >
                            <p>{formatNumber(displayValue)}</p>
                            <div
                              class="progress absolute {getClassName(displayValue, key)}-gradient bottom-0 left-0 w-full"
                              style="height:3px"
                            ></div>
                          </div>
                          <div
                            class="ml-2 md:ml-10 relative flex items-center justify-center flex-grow-0 md:flex-grow-0 sm:flex-grow xs:flex-grow flex-shrink-0 w-9 md:w-9 sm:w-auto xs:w-auto h-9 p-3 font-extrabold text-brand-gray whitespace-no-wrap bg-gray-100 text-body-small"
                            title="{competitorUrl}"
                          >
                            <p>
                              {formatNumber(competitorPerformanceMap.performanceKeyScore[i].displayValue)}
                            </p>
                            <div
                              class="progress absolute {getClassName(competitorPerformanceMap.performanceKeyScore[i].displayValue, key)}-gradient bottom-0 left-0 w-full"
                              style="height:3px"
                            ></div>
                          </div>
                        </div>
                      </article>
                    {/each}
                  </section>
                {/if}
              </div>
              <div class="mb-4 space-y-8">
                <button
                  class="font-extrabold tracking-wider text-label-small focus:outline-none hover:outline-none hover:text-brand-red hover:opacity-80"
                  on:click="{() => {
                    showMetrics = !showMetrics;
                    const node = document.getElementById('performance-score-title');
                    scrollIntoView(node, {
                      scrollMode: 'if-needed',
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}"
                >
                  Show
                  {showMetrics ? 'Less' : 'More'}
                </button>
              </div>
            </section>
            <section class="mt-5 space-y-4 md:mt-11 md:w-11/12">
              <p class="leading-relaxed tracking-wide">
                Want the complete analysis?
              </p>
              <button
                on:click="{openDownloadReportModal}"
                class="w-full px-5 py-2 font-extrabold tracking-wider text-white border-2 outline-none bg-brand-red border-brand-red text-label-small md:w-auto min-w-290"
                style="box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25)"
              >Get the report</button>
            </section>
            <section class="space-y-4 mt-8 md:mt-12">
              <h3>Page Speed</h3>
              <p>
                For mobile webpages, speed is everything. Improving your load
                time by 0.1s can boost conversion rates by 8%.
              </p>
            </section>
            <section class="relative mt-4">
              <h4>
                Load Times
                <span class="text-xs font-normal">(Lower is better)</span>
              </h4>

              <PageSpeedChart
                performanceMap="{performanceMap}"
                url="{url}"
                backgroundClass="bg-brand-gray"
                benchmarkClass=""
              />

              <PageSpeedChart
                performanceMap="{competitorPerformanceMap}"
                url="{competitorUrl}"
                backgroundClass=""
              />

              <PageSpeedChart
                performanceMap="{{ performanceKeyScore: [{}, { displayValue: industryAverages[category].displayValue, numericValue: industryAverages[category].average }], pageSpeedIndexPercentage: pageSpeedIndustryAveragePercentage, pageSpeedClass: pageSpeedIndustryAverageClass }}"
                url="INDUSTRY AVERAGE"
                backgroundClass="bg-brand-gray"
                benchmarkClass=""
              />

              <div class="flex justify-between mt-4 space-x-2 text-xs">
                <div class="flex space-x-1">
                  <span class="w-4 h-1 mt-2 bg-error"></span>
                  <div>
                    <span class="text-brand-gray">Poor</span>
                    <p class="text-gray-700">Above 6.5s</p>
                  </div>
                </div>
                <div class="flex space-x-1 ">
                  <span class="w-4 h-1 mt-2 bg-warning"></span>
                  <div>
                    <span class="text-brand-gray">Needs improvement</span>
                    <p class="text-gray-700">3.5-6.5s</p>
                  </div>
                </div>
                <div class="flex space-x-1">
                  <span class="w-4 h-1 mt-2 bg-success "></span>
                  <div>
                    <span class="text-brand-gray">Good</span>
                    <p class="text-gray-700">1-3.5s</p>
                  </div>
                </div>
                <div class="flex space-x-1">
                  <span class="w-4 h-4 mr-5px">
                    <img src="/assets/images/benchmark.svg" alt="benchmark" />
                  </span>
                  <div>
                    <span class="text-brand-gray">Benchmark</span>
                    <p class="text-gray-700">3.5s</p>
                  </div>
                </div>
              </div>
            </section>

            <section class="mt-5 space-y-4 md:mt-11 md:w-11/12">
              <h3>Boost your performance</h3>
              <p class="leading-relaxed tracking-wide">
                Get a detailed report of your website’s performance, and tips
                and tricks for improving it.
              </p>
              <button
                on:click="{openDownloadReportModal}"
                class="w-full px-5 py-2 font-extrabold tracking-wider text-white border-2 outline-none bg-brand-red border-brand-red text-label-small md:w-auto min-w-290"
                style="box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25)"
              >Download the full report</button>
              <!-- <button
                on:click="{backToReportPage}"
                class="w-full px-5 py-2 font-extrabold tracking-wider text-gray-700 truncate border-2 border-gray-300 outline-none text-label-small md:w-auto"
                style="box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25)"
              >Back to the report for
                {protocolLessDomain(url)}
              </button> -->
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Modal enabled="{isDownloadModalVisible}">
    <div
      style="min-width: 22vw;max-width: 600px;"
      on:keydown="{e => handleKeyDown(e, 'report', !isArrowOpaque)}"
      use:clickOutside="{{ enabled: isDownloadModalVisible, cb: closeReportModal}}"
      class="inline-block px-5 pt-6 pb-4 space-y-5 overflow-visible text-left align-bottom transition-all duration-500 transform bg-white shadow-xl sm:my-12 sm:align-middle sm:max-w-xs sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      transition:fly="{{ y: 500 }}"
      aria-labelledby="modal-headline"
      on:click="{event => event.stopPropagation()}"
    >
    <img
      class="fixed right-0 cursor-pointer"
      style="top: -35px"
      src="/assets/images/close.svg"
      alt="close"
      on:click="{closeReportModal}"
    />
      {#if !mailSent}
        <h4 class="text-brand-gray md:text-5xl sm:text-xl">
          Get the full report
        </h4>
        <p class="text--brand-gray-700 text-body-large break-words">
          We’ll send a copy of this comparison report to your inbox.
        </p>
        <div>
          <div class="relative">
            <label
              class="tracking-wider text-gray-700 uppercase text-body-large"
            >
              <!-- svelte-ignore a11y-autofocus -->
              <input
              type="email"
              bind:value="{reportEmail}"
              autofocus
              disabled={mailSending}
              placeholder="john.doe@example.com"
              class="w-full py-2 border-b-2 border-gray-300 outline-none text-brand-gray placeholder-gray-390"
            />
            </label>

            <div
              class="absolute bottom-0 right-0 flex w-10 h-full py-2 border border-transparent"
            >
              <div
                class="z-10 flex items-end justify-end w-full h-full text-lg bg-white text-brand-gray"
              >
                <img
                  class="{isArrowOpaque ? 'opacity-25' : 'opacity-1'} cursor-pointer"
                  src="/assets/images/arrow-right.svg"
                  alt="submit"
                  on:click="{sendReportToEmail}"
                />
              </div>
            </div>
          </div>
        </div>
        {#if !!mailSending}
          <div class="fixed bottom-0 left-0 w-full bg-brand-red transition-all" class:sending-mail={mailSending} style="height:4px"></div>
        {/if}
      {:else}
        <div class="flex items-center justify-center">
          <div>
            <h4 class="text-brand-gray md:text-5xl sm:text-xl">
              You've got mail!
            </h4>
            <p class="text--brand-gray-700 text-body-large pb-8 pt-2">
              Check your inbox for your<br>performance report.
            </p>
          </div>
          <img
            src="/assets/images/img-signup-success.svg"
            alt="mailbox"
            class="w-12 h-12"
          />
        </div>
      {/if}
    </div>
  </Modal>
  <Footer />
{:else}
  <Loader competitorUrl="{competitorUrl}" />
{/if}
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TB2K5G6"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
